<template>
    <div>

        <!-- shopify csv file import modal starts here -->
        <!-- <el-dialog 
            :visible.sync="csvPopUp" 
            title="Shopify Product CSV Import" 
            :show-close="true" 
            align-center 
            @closed="closeCsvPopupHandle('shopify')"
        >
            
            <el-steps :active="activeStep" finish-status="success" align-center>
                <el-step title="Get Started" />
                <el-step title="Upload File" />
                <el-step title="Map Columns" />
                <el-step title="Missing Data" />
                <el-step title="Import Data" />
            </el-steps>

            <el-divider />

            <div class="stepOneWrap" v-if="activeStep == 0">

                <div class="stepOneHeader">
                    <h1>Prepare To Initiate The Data Import Process</h1>
                    <p>Following columns are supported. Some may be required, the rest are optional.</p>
                </div>

                <el-table :data="myHeaders">
                    <el-table-column prop="name" label="COLUMN NAME"/>
                    <el-table-column prop="format.final" label="FORMAT"/>
                    <el-table-column prop="isRequired" label="REQUIRED"/>
                </el-table>

            </div>

            <div class="stepOneWrap" v-if="activeStep == 1">

                <div class="stepOneHeader">
                    <h1>Please Upload A CSV File Containing Products</h1>
                    <p>The first row of file should have headers in it.</p>
                </div>
                
                <div class="fileUploaderWrap">
                    <div class="row">
                        <div class="col-md-12" v-if="!csvFile">
                            <input type="file" @change.prevent="csvFileUploadHandle('shopify',$event)" id="csv_file_uploader" ref="shopifyCsvUploader" accept="text/csv" hidden />
                            <label for="csv_file_uploader" style="display: block">
                                <div class="category-tab-img" id="category-featured-image">
                                    <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g>
                                            <path d="M384,265H264v119h-17V265H128v-17h119V128h17v120h120V265z"/>
                                        </g>
                                    </svg>
                                </div>
                            </label>
                        </div>
                        <div class="col-md-12 fileDetailsBox" v-else>
                            <div class="fileBox">
                                <div class="fileDetailsWrap">
                                    <h3>
                                        File Name:
                                    </h3>
                                    <p>{{ csvFile.name }}</p>
                                </div>
                                <div class="fileDetailsWrap">
                                    <h3>
                                        Total Headers:
                                    </h3>
                                    <p>{{ csvFileHeaders.length > 0 ? csvFileHeaders.length : 0 }}</p>
                                </div>
                                <div class="fileDetailsWrap">
                                    <h3>
                                        Total Products:
                                    </h3>
                                    <p>{{ csvFileProducts.length }}</p>
                                </div>
                            </div>
                            <div class="fileRemoveBtn">
                                <el-button @click.prevent="csvRemovalHandle('shopify')">Remove File</el-button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="stepOneWrap" v-if="activeStep == 2">

                <div class="stepOneHeader" v-if="csvFileValidationErrors.length > 0">
                    <h1>The Uploaded File Contains Some Issues</h1>
                    <p>Kindly resolve the validation issues with the uploaded file and try again.</p>
                </div>
                <div class="stepOneHeader" v-else>
                    <h1>Map The Supported Columns With The Headers Found In Your File</h1>
                    <p>For the data to be successfully uploaded, it must meet all validation criteria.</p>
                </div>

                <div class="errorsBoxWrap" v-if="csvFileValidationErrors.length > 0">
                    <ul>
                        <li v-for="(validationError,index) in csvFileValidationErrors" :key="`error-`+ index">
                            {{ validationError }}
                        </li>
                    </ul>
                </div>

                <el-table :data="myHeaders" v-else>
                    <el-table-column prop="name" label="SUPPORTED COLUMN"/>
                    <el-table-column prop="mappedTo" label="FILE HEADER">
                        <template #default="scope">
                            <el-select v-model="scope.row.mappedTo" clearable filterable class="m-2" placeholder="Select" size="large">
                                <el-option 
                                    v-for="item in csvFileHeaders" 
                                    :key="item" 
                                    :label="item.name" 
                                    :value="item" 
                                    :disabled="valueMappedCheck('shopify',item)"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="stepOneWrap" v-if="activeStep == 3">

                <div class="stepOneHeader">
                    <h1>It's Likely That Certain Data Elements Are Missing From The Uploaded File</h1>
                    <p>These values can help improve the overall experiance of your products.</p>
                </div>

                <div class="stepOneHeader">
                    <p>It's typical for Shopify CSV files to be missing product stock data. You can adjust it from the fields given below:</p>

                    <div class="productsBox">
                        <div class="masterInputWrap">
                            <div class="row">
                                <div class="col-md-8 suggestionCol">
                                    <p>This field enables you to specify a default stock value for all available products:</p>
                                </div>
                                <div class="col-md-4">
                                    <form>
                                        <input
                                            type="text"
                                            placeholder="Add stock value here"
                                            name="default-quantity"
                                            id="default-quantity"
                                            v-model="csvDefaultQty"
                                        />
                                        <button type="submit" @click.prevent="csvAddDefaultQtyHandle('shopify')">Add</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="productDataWrap" v-if="currentPageProducts">
                            <ValidationObserver ref="csvProductsObserver">
                                <div class="productInputWrap" v-for="(csvProduct,productIndex) in currentPageProducts.products" :key="`shopify_csvProduct-${productIndex}`">
                                
                                    <div class="productVariantsWrap">
                                        <div class="productVariantContainer">
                                            <h6>{{ csvProduct.product_type == 'simple' ? csvProduct.name : csvProduct.name + ` (${csvProduct.variant.name})` }}</h6>
                                        <div class="tableFromFieldBox">
                                            <ValidationProvider :vid="`csv_variant_${productIndex}`" name="stock" :rules="{ required:true, regex:/^(?!0\d)\d*$/, min_value:0, max_value: 10000000}" v-slot="{ errors }">
                                                <div class="stockProductField">
                                                    <button class="miniusIconBtn" @click.prevent=" csvProduct.variant.qty == 0 ? 0 : csvProduct.variant.qty-- ">
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g id="_-" data-name=" -" class="cls-1">
                                                                    <g class="cls-1">
                                                                        <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <input
                                                        type="text"
                                                        :name="`csvStock-${productIndex}`"
                                                        :id="`csvStock-${productIndex}`"
                                                        placeholder="Add stock value here"
                                                        v-model="csvProduct.variant.qty"
                                                    />
                                                    <button class="plusIconBtn" @click.prevent="csvProduct.variant.qty++">
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g id="_" data-name=" " class="cls-1">
                                                                    <g class="cls-1">
                                                                        <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            </ValidationObserver>
                        </div>

                    </div>

                </div>

                <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-count="mainPagination.total"
                    class="mt-4"
                    @current-change="mainPaginationCurrentPageChangeHandle"
                />

            </div>

            <div class="stepOneWrap" v-if="activeStep == 4">

                <div class="stepOneHeader">
                    <h1>Important Considerations Before Starting the Import</h1>
                    <p>Here are some essential factors to remember before commencing the import.</p>
                    <ul>
                        <li>
                            The product and variant images will be automatically retrieved from the 'Image Src' column present in the file. 
                            If the <br>column is not found in the CSV file, the products will be imported without any associated images.
                        </li>
                        <li>
                            Products and variants quantity will be picked from 'Variant Inventory Qty' column present in the file. 
                            If the CSV file doesn't<br>include this column, the products and their variants will be imported with a quantity set to zero.
                        </li>
                        <li>
                            If the file contains a product with a name or slug 
                            that closely matches an already listed product in your store, it will not <br>be added again to prevent duplicate products and slugs.
                        </li>
                        <li>
                            The import process may be stopped if the store exhausts its disk space, 
                            but any products that were successfully<br>imported before the storage limit was reached will still be listed in your store.
                        </li>
                        <li>
                            The upload process will begin with the products, with attachments being uploaded afterwards.
                        </li>
                        <li>
                            Whether the import is successful or unsuccessful, you will receive an email notification that 
                            includes the list of product<br>names that were successfully imported.
                        </li>
                    </ul>
                </div>

            </div>
            
            <el-divider />

            <div class="stepsActionBtns">

                <el-button @click="csvPopUpStepHandle('shopify','decr')" :disabled="activeStep == 0">
                    Previous Step
                </el-button>

                <el-button @click="csvPopUpStepHandle('shopify','incr')" :disabled="activeStep == 1 && !csvFile">
                    {{ activeStep == 4 ? 'Initiate Import' : 'Next Step' }}
                </el-button>

            </div>

        </el-dialog> -->

        <transition name="modal">
            <div class="modalBg categoryResponsiveModal modal-active" v-if="csvPopUp">
                <div class="modalLayers" @click.prevent="closeCsvPopupHandle('shopify')"></div>
                <div class="modalBody">

                    <div class="addProductTitle">
                        <h3>Shopify Product Csv Import</h3>
                    </div>

                    <el-steps :active="activeStep" finish-status="success" align-center>
                        <el-step title="Get Started" />
                        <el-step title="Upload File" />
                        <el-step title="Map Columns" />
                        <el-step title="Missing Data" />
                        <el-step title="Import Data" />
                    </el-steps>

                    <hr>

                    <!-- step 1 starts here -->
                    <div class="stepOneWrap" v-if="activeStep == 0">
                        <div class="stepOneHeader">
                            <h1>Prepare To Initiate The Data Import Process</h1>
                            <p>Following columns are supported. Some may be required, the rest are optional.</p>
                        </div>
                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;overflow: auto;" v-if="activeStep == 0">
                        <div id="London" class="tabcontent">
                            <div class="content-table">
                                <table class="table">
                                    <thead>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Column Name">Column Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Format">Format</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Required">Required</p>
                                            </div>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(header,index) in myHeaders" :key="`shopify-header-${index}`">
                                            
                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ header.name }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ header.format.final }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ header.isRequired }}</p>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- step 1 ends here -->

                    <!-- step 2 starts here -->
                    <div class="stepOneWrap" v-if="activeStep == 1">
                        <div class="stepOneHeader">
                            <h1>Please Upload A CSV File Containing Products</h1>
                            <p>The first row of file should have headers in it.</p>
                        </div>
                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;overflow: auto;" v-if="activeStep == 1">
                        <div id="London" class="tabcontent">
                            <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                                <div class="editCategoryBlock createCategoryPopup">
                                    <div class="row">
                                        <div class="col-md-12" v-if="!csvFile">
                                            <input type="file" @change.prevent="csvFileUploadHandle('shopify',$event)" id="csv_file_uploader" ref="shopifyCsvUploader" accept="text/csv" hidden />
                                            <label for="csv_file_uploader" style="display: block">
                                                <div class="category-tab-img" style="height: 350px;">
                                                    <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g>
                                                            <path d="M384,265H264v119h-17V265H128v-17h119V128h17v120h120V265z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="col-md-12 fileDetailsBox" v-else>
                                            <table class="table">
                                                <thead>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="File Name">File Name</p>
                                                        </div>
                                                    </th>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Total Headers">Total Headers</p>
                                                        </div>
                                                    </th>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Total Products">Total Products</p>
                                                        </div>
                                                    </th>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                                        </div>
                                                    </th>
                                                </thead>
                                                <tbody>

                                                    <tr>

                                                        <td>
                                                            <div class="tableBodyCtmTxt">
                                                                <p>{{ csvFile.name }}</p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div class="tableBodyCtmTxt">
                                                                <p>{{ csvFileHeaders.length > 0 ? csvFileHeaders.length : 0 }}</p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div class="tableBodyCtmTxt">
                                                                <p>{{ csvFileProducts.length }}</p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div class="ctmDataTableActionBtn">
                                                                <button class="delFunction" @click.prevent="csvRemovalHandle('shopify')">
                                                                    <i class="fa fa-trash-o"></i>
                                                                </button>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- step 2 ends here -->

                    <!-- step 3 starts here -->
                    <div class="stepOneWrap" v-if="activeStep == 2">

                        <div class="stepOneHeader" v-if="csvFileValidationErrors.length > 0">
                            <h1>The Uploaded File Contains Some Issues</h1>
                            <p>Kindly resolve the validation issues with the uploaded file and try again.</p>
                        </div>
                        <div class="stepOneHeader" v-else>
                            <h1>Map The Supported Columns With The Headers Found In Your File</h1>
                            <p>For the data to be successfully uploaded, it must meet all validation criteria.</p>
                        </div>

                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;overflow: auto;" v-if="activeStep == 2">
                        <div id="London" class="tabcontent">
                            <div class="content-table">

                                <table class="table" v-if="csvFileValidationErrors.length > 0">
                                    <thead>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Supported Columns">Error Details</p>
                                            </div>
                                        </th>
                                    </thead>
                                    <tbody>
                                        
                                        <tr v-for="(validationError,index) in csvFileValidationErrors" :key="`shopify-file-error-${index}`">

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ validationError }}</p>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <table class="table" v-else>
                                    <thead>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Supported Columns">Supported Columns</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="File Headers">File Headers</p>
                                            </div>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(header,index) in myHeaders" :key="`shopify-map-header-${index}`">

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ header.name }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <el-select v-model="header.mappedTo" clearable filterable class="m-1" placeholder="Select" size="large">
                                                    <el-option 
                                                        v-for="(item,i) in csvFileHeaders" 
                                                        :key="`header-${index}-${i}`" 
                                                        :label="item.name" 
                                                        :value="item" 
                                                        :disabled="valueMappedCheck('shopify',item)"
                                                    />
                                                </el-select>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <!-- step 3 ends here -->

                    <!-- step 4 starts here -->
                    <div class="stepOneWrap" v-if="activeStep == 3">

                        <div class="stepOneHeader">
                            <h1>It's Likely That Certain Data Elements Are Missing From The Uploaded File</h1>
                            <p>These values can help improve the overall experiance of your products.
                                It's typical for Shopify CSV files to be missing product stock data. You can adjust it from the fields given below:
                            </p>

                            <div class="productsBox">
                                <div class="masterInputWrap">
                                    <div class="row">
                                        <div class="col-md-6 suggestionCol">
                                            <p>This field enables you to specify a default stock value for all available products:</p>
                                        </div>
                                        <div class="col-md-6">
                                            <form>
                                                <input
                                                    type="text"
                                                    placeholder="Add stock value here"
                                                    name="default-quantity"
                                                    id="default-quantity"
                                                    v-model="csvDefaultQty"
                                                />
                                                <button type="submit" @click.prevent="csvAddDefaultQtyHandle('shopify')">Add</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 250px; max-height: 250px;overflow: auto;" v-if="activeStep == 3 && currentPageProducts">
                        <div id="London" class="tabcontent">
                            <div class="content-table">

                                <ValidationObserver ref="csvProductsObserver">
                                    <table class="table">
                                        <thead>
                                            <th class="ctmPr-3">
                                                <div class="ctmTableTitleWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Product (variant)">Product (Variant)</p>
                                                </div>
                                            </th>
                                            <th class="ctmPr-3">
                                                <div class="ctmTableTitleWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Stock">Stock</p>
                                                </div>
                                            </th>
                                        </thead>
                                        <tbody>

                                            <tr v-for="(csvProduct,productIndex) in currentPageProducts.products" :key="`shopify-csv-product-${productIndex}`">

                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ csvProduct.product_type == 'simple' ? csvProduct.name : csvProduct.name + ` (${csvProduct.variant.name})` }}</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="tableBodyCtmTxt" >
                                                        <ValidationProvider 
                                                            :vid="`csv_product_variant_${productIndex}`" 
                                                            name="stock" 
                                                            :rules="{ required:true, regex:/^(?!0\d)\d*$/, min_value:0, max_value: 10000000}" 
                                                            v-slot="{ errors }"
                                                        >
                                                            <div class="stockProductField" style="width:150px">
                                                                <button class="miniusIconBtn" @click.prevent=" csvProduct.variant.qty == 0 ? 0 : csvProduct.variant.qty-- ">
                                                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                                            <g id="_-" data-name=" -" class="cls-1">
                                                                                <g class="cls-1">
                                                                                    <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                                <input
                                                                    type="text"
                                                                    :name="`csvStock-${productIndex}`"
                                                                    :id="`csvStock-${productIndex}`"
                                                                    placeholder="Add stock value here"
                                                                    v-model="csvProduct.variant.qty"
                                                                />
                                                                <button class="plusIconBtn" @click.prevent="csvProduct.variant.qty++">
                                                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                                            <g id="_" data-name=" " class="cls-1">
                                                                                <g class="cls-1">
                                                                                    <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </ValidationObserver>

                            </div>
                        </div>
                    </div>

                    <el-pagination
                        v-if="activeStep == 3"
                        background
                        layout="prev, pager, next"
                        :page-count="mainPagination.total"
                        class="mt-4"
                        @current-change="mainPaginationCurrentPageChangeHandle"
                    />
                    <!-- step 4 ends here -->

                    <!-- step 5 starts here -->
                    <div class="stepOneWrap" v-if="activeStep == 4">

                        <div class="stepOneHeader">
                            <h1>Important Considerations Before Starting the Import</h1>
                            <p>Here are some essential factors to remember before commencing the import.</p>
                            <ul>
                                <li>
                                    The product and variant images will be automatically retrieved from the 'Image Src' column present in the file. 
                                    If the <br>column is not found in the CSV file, the products will be imported without any associated images.
                                </li>
                                <li>
                                    Products and variants quantity will be picked from 'Variant Inventory Qty' column present in the file. 
                                    If the CSV file doesn't<br>include this column, the products and their variants will be imported with a quantity set to zero.
                                </li>
                                <li>
                                    If the file contains a product with a name or slug 
                                    that closely matches an already listed product in your store, it will not <br>be added again to prevent duplicate products and slugs.
                                </li>
                                <li>
                                    The import process may be stopped if the store exhausts its disk space, 
                                    but any products that were successfully<br>imported before the storage limit was reached will still be listed in your store.
                                </li>
                                <li>
                                    The upload process will begin with the products, with attachments being uploaded afterwards.
                                </li>
                                <li>
                                    Whether the import is successful or unsuccessful, you will receive an email notification that 
                                    includes the list of product<br>names that were successfully imported.
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                    <!-- step 5 ends here -->

                    <hr>

                    <div class="stepsActionBtns">

                        <el-button @click="csvPopUpStepHandle('shopify','decr')" :disabled="activeStep == 0">
                            Previous Step
                        </el-button>

                        <el-button @click="csvPopUpStepHandle('shopify','incr')" :disabled="activeStep == 1 && !csvFile">
                            {{ activeStep == 4 ? 'Initiate Import' : 'Next Step' }}
                        </el-button>

                    </div>

                    <div class="modalCloseIcon">
                        <button @click="closeCsvPopupHandle('shopify')">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>

                </div>
            </div>
        </transition>
        <!-- shopify csv file import modal ends here -->

        <!-- woocommerce csv file import modal starts here -->
        <!-- <el-dialog 
            :visible.sync="woocommerceCsvPopup" 
            title="Woocommerce Product CSV Import" 
            :show-close="true" 
            align-center 
            @closed="closeCsvPopupHandle('woocommerce')"
        >
      
            <el-steps :active="woocommerceActiveStep" finish-status="success" align-center>
                <el-step title="Get Started" />
                <el-step title="Upload File" />
                <el-step title="Map Columns" />
                <el-step title="Missing Data" />
                <el-step title="Import Data" />
            </el-steps>

            <el-divider />

            <div class="stepOneWrap" v-if="woocommerceActiveStep == 0">

                <div class="stepOneHeader">
                    <h1>Prepare To Initiate The Data Import Process</h1>
                    <p>
                        Following columns are supported. Some may be required, the rest are optional. 
                        Currently, simple and variable products are supported by our system.
                    </p>
                </div>

                <el-table :data="woocommerceHeaders">
                    <el-table-column prop="name" label="COLUMN NAME"/>
                    <el-table-column prop="format.final" label="FORMAT"/>
                    <el-table-column prop="isRequired" label="REQUIRED"/>
                </el-table>

            </div>

            <div class="stepOneWrap" v-if="woocommerceActiveStep == 1">

                <div class="stepOneHeader">
                    <h1>Please Upload A CSV File Containing Products</h1>
                    <p>The first row of file should have headers in it.</p>
                </div>

                <div class="fileUploaderWrap">
                    <div class="row">
                        <div class="col-md-12" v-if="!woocommerceCsvFile">
                            <input type="file" @change.prevent="csvFileUploadHandle('woocommerce',$event)" id="csv_file_uploader" ref="woocommerceCsvUploader" accept="text/csv" hidden>
                            <label for="csv_file_uploader" style="display: block">
                                <div class="category-tab-img" id="category-featured-image">
                                    <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g>
                                            <path d="M384,265H264v119h-17V265H128v-17h119V128h17v120h120V265z"/>
                                        </g>
                                    </svg>
                                </div>
                            </label>
                        </div>
                        <div class="col-md-12 fileDetailsBox" v-else>
                            <div class="fileBox">
                                <div class="fileDetailsWrap">
                                    <h3>
                                        File Name:
                                    </h3>
                                    <p>{{ woocommerceCsvFile.name }}</p>
                                </div>
                                <div class="fileDetailsWrap">
                                    <h3>
                                        Total Headers:
                                    </h3>
                                    <p>{{ woocommerceCsvFileHeaders.length > 0 ? woocommerceCsvFileHeaders.length : 0 }}</p>
                                </div>
                                <div class="fileDetailsWrap">
                                    <h3>
                                        Total Products:
                                    </h3>
                                    <p>{{ woocommerceCsvFileProducts.length }}</p>
                                </div>
                            </div>
                            <div class="fileRemoveBtn">
                                <el-button @click.prevent="csvRemovalHandle('woocommerce')">Remove File</el-button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="stepOneWrap" v-if="woocommerceActiveStep == 2">

                <div class="stepOneHeader" v-if="woocommerceCsvFileValidationErrors.length > 0">
                    <h1>The Uploaded File Contains Some Issues</h1>
                    <p>Kindly resolve the validation issues with the uploaded file and try again.</p>
                </div>
                <div class="stepOneHeader" v-else>
                    <h1>Map The Supported Columns With The Headers Found In Your File</h1>
                    <p>For the data to be successfully uploaded, it must meet all validation criteria.</p>
                </div>

                <div class="errorsBoxWrap" v-if="woocommerceCsvFileValidationErrors.length > 0">
                    <ul>
                        <li v-for="(validationError,index) in woocommerceCsvFileValidationErrors" :key="`error-`+ index">
                            {{ validationError }}
                        </li>
                    </ul>
                </div>

                <el-table :data="woocommerceHeaders" v-else>
                    <el-table-column prop="name" label="SUPPORTED COLUMN"/>
                    <el-table-column prop="mappedTo" label="FILE HEADER">
                        <template #default="scope">
                            <el-select v-model="scope.row.mappedTo" clearable filterable class="m-2" placeholder="Select" size="large">
                                <el-option 
                                    v-for="item in woocommerceCsvFileHeaders" 
                                    :key="item" 
                                    :label="item.name" 
                                    :value="item" 
                                    :disabled="valueMappedCheck('woocommerce',item)"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="stepOneWrap" v-if="woocommerceActiveStep == 3">

                <div class="stepOneHeader">
                    <h1>It's Likely That Certain Data Elements Are Missing From The Uploaded File</h1>
                    <p>These values can help improve the overall experiance of your products.</p>
                </div>

                <div class="stepOneHeader">
                    <p>It's typical for Woocommerce CSV files to be missing product stock data. You can adjust it from the fields given below:</p>

                    <div class="productsBox">
                        <div class="masterInputWrap">
                            <div class="row">
                                <div class="col-md-8 suggestionCol">
                                    <p>This field enables you to specify a default stock value for all available products:</p>
                                </div>
                                <div class="col-md-4">
                                    <form>
                                        <input
                                            type="text"
                                            placeholder="Add stock value here"
                                            name="woocommerce-default-quantity"
                                            id="woocommerce-default-quantity"
                                            v-model="woocommerceCsvDefaultQty"
                                        />
                                        <button type="submit" @click.prevent="csvAddDefaultQtyHandle('woocommerce')">Add</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="productDataWrap" v-if="woocommerceCurrentPageProducts">
                            <ValidationObserver ref="woocommerceCsvProductsObserver">
                                <div class="productInputWrap" v-for="(csvProduct,productIndex) in woocommerceCurrentPageProducts.products" :key="`woocommerce_csvProduct_${productIndex}`">
                                
                                    <div class="productVariantsWrap">
                                        <div class="productVariantContainer" v-if="csvProduct.variant.isActive == 1">
                                            <h6>{{ csvProduct.product_type == 'simple' ? csvProduct.name : csvProduct.name + ` (${csvProduct.variant.name})` }}</h6>
                                            <div class="tableFromFieldBox">
                                                <ValidationProvider :vid="`csv_variant_${productIndex}`" name="stock" :rules="{ required:true, regex:/^(?!0\d)\d*$/, min_value:0, max_value: 10000000}" v-slot="{ errors }">
                                                <div class="stockProductField">
                                                    <button class="miniusIconBtn" @click.prevent=" csvProduct.variant.qty == 0 ? 0 : csvProduct.variant.qty-- ">
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g id="_-" data-name=" -" class="cls-1">
                                                                    <g class="cls-1">
                                                                        <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <input
                                                        type="text"
                                                        :name="`csvStock_${productIndex}`"
                                                        :id="`csvStock_${productIndex}`"
                                                        placeholder="Add stock value here"
                                                        v-model="csvProduct.variant.qty"
                                                    />
                                                    <button class="plusIconBtn" @click.prevent="csvProduct.variant.qty++">
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g id="_" data-name=" " class="cls-1">
                                                                    <g class="cls-1">
                                                                        <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </ValidationObserver>
                        </div>

                    </div>

                </div>

                <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-count="woocommerceMainPagination.total"
                    class="mt-4"
                    @current-change="woocommerceMainPaginationCurrentPageChangeHandle"
                />

            </div>

            <div class="stepOneWrap" v-if="woocommerceActiveStep == 4">

                <div class="stepOneHeader">
                    <h1>Important Considerations Before Starting the Import</h1>
                    <p>Here are some essential factors to remember before commencing the import.</p>
                    <ul>
                        <li>
                            The product and variant images will be automatically retrieved from the 'Images' column present in the file. 
                            If the <br>column is not found in the CSV file, the products will be imported without any associated images.
                        </li>
                        <li>
                            Products and variants quantity will be picked from 'Stock' column present in the file. 
                            If the CSV file doesn't<br>include this column, the products and their variants will be imported with a quantity set to zero.
                        </li>
                        <li>
                            If the file contains a product with a name or slug 
                            that closely matches an already listed product in your store, it will not <br>be added again to prevent duplicate products and slugs.
                        </li>
                        <li>
                            The import process may be stopped if the store exhausts its disk space, 
                            but any products that were successfully<br>imported before the storage limit was reached will still be listed in your store.
                        </li>
                        <li>
                            The upload process will begin with the products, with attachments being uploaded afterwards.
                        </li>
                        <li>
                            Whether the import is successful or unsuccessful, you will receive an email notification that 
                            includes the list of product<br>names that were successfully imported.
                        </li>
                    </ul>
                </div>

            </div>

            <el-divider />

            <div class="stepsActionBtns">

                <el-button @click="csvPopUpStepHandle('woocommerce','decr')" :disabled="woocommerceActiveStep == 0">
                    Previous Step
                </el-button>

                <el-button @click="csvPopUpStepHandle('woocommerce','incr')" :disabled="woocommerceActiveStep == 1 && !woocommerceCsvFile">
                    {{ woocommerceActiveStep == 4 ? 'Initiate Import' : 'Next Step' }}
                </el-button>

            </div>

        </el-dialog> -->
        
        <transition name="modal">
            <div class="modalBg categoryResponsiveModal modal-active" v-if="woocommerceCsvPopup">
                <div class="modalLayers" @click.prevent="closeCsvPopupHandle('woocommerce')"></div>
                <div class="modalBody">

                    <div class="addProductTitle">
                        <h3>Woocommerce Product Csv Import</h3>
                    </div>

                    <el-steps :active="woocommerceActiveStep" finish-status="success" align-center>
                        <el-step title="Get Started" />
                        <el-step title="Upload File" />
                        <el-step title="Map Columns" />
                        <el-step title="Missing Data" />
                        <el-step title="Import Data" />
                    </el-steps>

                    <hr>

                    <!-- step 1 starts here -->
                    <div class="stepOneWrap" v-if="woocommerceActiveStep == 0">
                        <div class="stepOneHeader">
                            <h1>Prepare To Initiate The Data Import Process</h1>
                            <p>
                                Following columns are supported. Some may be required, the rest are optional. 
                                Currently, simple and variable products are supported by our system.
                            </p>
                        </div>
                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;overflow: auto;" v-if="woocommerceActiveStep == 0">
                        <div id="London" class="tabcontent">
                            <div class="content-table">
                                <table class="table">
                                    <thead>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Column Name">Column Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Format">Format</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Required">Required</p>
                                            </div>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(header,index) in woocommerceHeaders" :key="`shopify-header-${index}`">
                                            
                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ header.name }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ header.format.final }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ header.isRequired }}</p>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- step 1 ends here -->

                    <!-- step 2 starts here -->
                    <div class="stepOneWrap" v-if="woocommerceActiveStep == 1">
                        <div class="stepOneHeader">
                            <h1>Please Upload A CSV File Containing Products</h1>
                            <p>The first row of file should have headers in it.</p>
                        </div>
                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;overflow: auto;" v-if="woocommerceActiveStep == 1">
                        <div id="London" class="tabcontent">
                            <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                                <div class="editCategoryBlock createCategoryPopup">
                                    <div class="row">
                                        <div class="col-md-12" v-if="!woocommerceCsvFile">
                                            <input type="file" @change.prevent="csvFileUploadHandle('woocommerce',$event)" id="csv_file_uploader" ref="woocommerceCsvUploader" accept="text/csv" hidden>
                                            <label for="csv_file_uploader" style="display: block">
                                                <div class="category-tab-img" style="height: 350px;">
                                                    <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g>
                                                            <path d="M384,265H264v119h-17V265H128v-17h119V128h17v120h120V265z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="col-md-12 fileDetailsBox" v-else>
                                            <table class="table">
                                                <thead>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="File Name">File Name</p>
                                                        </div>
                                                    </th>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Total Headers">Total Headers</p>
                                                        </div>
                                                    </th>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Total Products">Total Products</p>
                                                        </div>
                                                    </th>
                                                    <th class="ctmPr-3">
                                                        <div class="ctmTableTitleWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                                        </div>
                                                    </th>
                                                </thead>
                                                <tbody>

                                                    <tr>

                                                        <td>
                                                            <div class="tableBodyCtmTxt">
                                                                <p>{{ woocommerceCsvFile.name }}</p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div class="tableBodyCtmTxt">
                                                                <p>{{ woocommerceCsvFileHeaders.length > 0 ? woocommerceCsvFileHeaders.length : 0 }}</p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div class="tableBodyCtmTxt">
                                                                <p>{{ woocommerceCsvFileProducts.length }}</p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div class="ctmDataTableActionBtn">
                                                                <button class="delFunction" @click.prevent="csvRemovalHandle('woocommerce')">
                                                                    <i class="fa fa-trash-o"></i>
                                                                </button>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- step 2 ends here -->

                    <!-- step 3 starts here -->
                    <div class="stepOneWrap" v-if="woocommerceActiveStep == 2">

                        <div class="stepOneHeader" v-if="woocommerceCsvFileValidationErrors.length > 0">
                            <h1>The Uploaded File Contains Some Issues</h1>
                            <p>Kindly resolve the validation issues with the uploaded file and try again.</p>
                        </div>
                        <div class="stepOneHeader" v-else>
                            <h1>Map The Supported Columns With The Headers Found In Your File</h1>
                            <p>For the data to be successfully uploaded, it must meet all validation criteria.</p>
                        </div>

                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;overflow: auto;" v-if="woocommerceActiveStep == 2">
                        <div id="London" class="tabcontent">
                            <div class="content-table">

                                <table class="table" v-if="woocommerceCsvFileValidationErrors.length > 0">
                                    <thead>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Error Details">Error Details</p>
                                            </div>
                                        </th>
                                    </thead>
                                    <tbody>
                                        
                                        <tr v-for="(validationError,index) in woocommerceCsvFileValidationErrors" :key="`shopify-file-error-${index}`">

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ validationError }}</p>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <table class="table" v-else>
                                    <thead>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Supported Columns">Supported Columns</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="File Headers">File Headers</p>
                                            </div>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(header,index) in woocommerceHeaders" :key="`shopify-map-header-${index}`">

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ header.name }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <el-select v-model="header.mappedTo" clearable filterable class="m-1" placeholder="Select" size="large">
                                                    <el-option 
                                                        v-for="(item,i) in woocommerceCsvFileHeaders" 
                                                        :key="`header-${index}-${i}`" 
                                                        :label="item.name" 
                                                        :value="item" 
                                                        :disabled="valueMappedCheck('woocommerce',item)"
                                                    />
                                                </el-select>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <!-- step 3 ends here -->

                    <!-- step 4 starts here -->
                    <div class="stepOneWrap" v-if="woocommerceActiveStep == 3">

                        <div class="stepOneHeader">
                            <h1>It's Likely That Certain Data Elements Are Missing From The Uploaded File</h1>
                            <p>These values can help improve the overall experiance of your products.
                                It's typical for Shopify CSV files to be missing product stock data. You can adjust it from the fields given below:
                            </p>

                            <div class="productsBox">
                                <div class="masterInputWrap">
                                    <div class="row">
                                        <div class="col-md-6 suggestionCol">
                                            <p>This field enables you to specify a default stock value for all available products:</p>
                                        </div>
                                        <div class="col-md-6">
                                            <form>
                                                <input
                                                    type="text"
                                                    placeholder="Add stock value here"
                                                    name="woocommerce-default-quantity"
                                                    id="woocommerce-default-quantity"
                                                    v-model="woocommerceCsvDefaultQty"
                                                />
                                                <button type="submit" @click.prevent="csvAddDefaultQtyHandle('woocommerce')">Add</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 250px; max-height: 250px;overflow: auto;" v-if="woocommerceActiveStep == 3 && woocommerceCurrentPageProducts">
                        <div id="London" class="tabcontent">
                            <div class="content-table">

                                <ValidationObserver ref="woocommerceCsvProductsObserver">
                                    <table class="table">
                                        <thead>
                                            <th class="ctmPr-3">
                                                <div class="ctmTableTitleWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Product (variant)">Product (Variant)</p>
                                                </div>
                                            </th>
                                            <th class="ctmPr-3">
                                                <div class="ctmTableTitleWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Stock">Stock</p>
                                                </div>
                                            </th>
                                        </thead>
                                        <tbody>

                                            <tr v-for="(csvProduct,productIndex) in woocommerceCurrentPageProducts.products" :key="`shopify-csv-product-${productIndex}`">

                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ csvProduct.product_type == 'simple' ? csvProduct.name : csvProduct.name + ` (${csvProduct.variant.name})` }}</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <ValidationProvider 
                                                            :vid="`csv_product_variant_${productIndex}`" 
                                                            name="stock" 
                                                            :rules="{ required:true, regex:/^(?!0\d)\d*$/, min_value:0, max_value: 10000000}" 
                                                            v-slot="{ errors }"
                                                        >
                                                            <div class="stockProductField" style="width:150px">
                                                                <button class="miniusIconBtn" @click.prevent=" csvProduct.variant.qty == 0 ? 0 : csvProduct.variant.qty-- ">
                                                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                                            <g id="_-" data-name=" -" class="cls-1">
                                                                                <g class="cls-1">
                                                                                    <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                                <input
                                                                    type="text"
                                                                    :name="`csvStock-${productIndex}`"
                                                                    :id="`csvStock-${productIndex}`"
                                                                    placeholder="Add stock value here"
                                                                    v-model="csvProduct.variant.qty"
                                                                />
                                                                <button class="plusIconBtn" @click.prevent="csvProduct.variant.qty++">
                                                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                                            <g id="_" data-name=" " class="cls-1">
                                                                                <g class="cls-1">
                                                                                    <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </ValidationObserver>

                            </div>
                        </div>
                    </div>

                    <el-pagination
                        v-if="woocommerceActiveStep == 3"
                        background
                        layout="prev, pager, next"
                        :page-count="woocommerceMainPagination.total"
                        class="mt-4"
                        @current-change="woocommerceMainPaginationCurrentPageChangeHandle"
                    />
                    <!-- step 4 ends here -->

                    <!-- step 5 starts here -->
                    <div class="stepOneWrap" v-if="woocommerceActiveStep == 4">

                        <div class="stepOneHeader">
                            <h1>Important Considerations Before Starting the Import</h1>
                            <p>Here are some essential factors to remember before commencing the import.</p>
                            <ul>
                                <li>
                                    The product and variant images will be automatically retrieved from the 'Images' column present in the file. 
                                    If the <br>column is not found in the CSV file, the products will be imported without any associated images.
                                </li>
                                <li>
                                    Products and variants quantity will be picked from 'Stock' column present in the file. 
                                    If the CSV file doesn't<br>include this column, the products and their variants will be imported with a quantity set to zero.
                                </li>
                                <li>
                                    If the file contains a product with a name or slug 
                                    that closely matches an already listed product in your store, it will not <br>be added again to prevent duplicate products and slugs.
                                </li>
                                <li>
                                    The import process may be stopped if the store exhausts its disk space, 
                                    but any products that were successfully<br>imported before the storage limit was reached will still be listed in your store.
                                </li>
                                <li>
                                    The upload process will begin with the products, with attachments being uploaded afterwards.
                                </li>
                                <li>
                                    Whether the import is successful or unsuccessful, you will receive an email notification that 
                                    includes the list of product<br>names that were successfully imported.
                                </li>
                            </ul>
                        </div>

                    </div>
                    <!-- step 5 ends here -->

                    <hr>

                    <div class="stepsActionBtns">

                        <el-button @click="csvPopUpStepHandle('woocommerce','decr')" :disabled="woocommerceActiveStep == 0">
                            Previous Step
                        </el-button>

                        <el-button @click="csvPopUpStepHandle('woocommerce','incr')" :disabled="woocommerceActiveStep == 1 && !woocommerceCsvFile">
                            {{ woocommerceActiveStep == 4 ? 'Initiate Import' : 'Next Step' }}
                        </el-button>

                    </div>

                    <div class="modalCloseIcon">
                        <button @click="closeCsvPopupHandle('shopify')">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>

                </div>
            </div>
        </transition>
        <!-- woocommerce csv file import modal ends here -->

    </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from "vuex";

export default {
    name: "productCsvImporter",
    data() {
        return {

            //shopify csv import start
            myHeaders: [
                {
                    name:'Name *',
                    columnName:'name',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Slug *',
                    columnName:'slug',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Description *',
                    columnName:'description',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:5000,
                        type:'String',
                        final:'String  | 5000 Characters'
                    }
                },
                {
                    name:'Category *',
                    columnName:'category',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Tags *',
                    columnName:'tags',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Published *',
                    columnName:'isActive',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:500,
                        type:'Boolean',
                        final:'Boolean | True/False'
                    }
                },
                {
                    name:'Short Description',
                    columnName:'short_description',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:300,
                        type:'String',
                        final:'String  | 300 Characters'
                    }
                },
                {
                    name:'SEO Title',
                    columnName:'meta_title',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'SEO Description',
                    columnName:'meta_description',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:500,
                        type:'String',
                        final:'String  | 500 Characters'
                    }
                },
                {
                    name:'SEO Keywords',
                    columnName:'keywords',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
            ],
            tempProductHeaders: [
                {
                    name:'Name *',
                    columnName:'name',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Slug *',
                    columnName:'slug',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Description *',
                    columnName:'description',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:5000,
                        type:'String',
                        final:'String  | 5000 Characters'
                    }
                },
                {
                    name:'Category *',
                    columnName:'category',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Tags *',
                    columnName:'tags',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Published *',
                    columnName:'isActive',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        type:'Boolean',
                        final:'Boolean | True/False'
                    }
                },
                {
                    name:'Short Description',
                    columnName:'short_description',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:300,
                        type:'String',
                        final:'String  | 300 Characters'
                    }
                },
                {
                    name:'SEO Title',
                    columnName:'meta_title',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'SEO Description',
                    columnName:'meta_description',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:500,
                        type:'String',
                        final:'String  | 500 Characters'
                    }
                },
                {
                    name:'SEO Keywords',
                    columnName:'keywords',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
            ],
            csvPopUp: false,
            csvFile: null,
            csvFileHeaders: [],
            csvFileData: [],
            csvFileProducts: [],
            activeStep: 0,
            csvFileValidationErrors: [],
            finalizedCsvProducts: [],
            currentPageProducts: null,
            csvDefaultQty: 0,
            mainPagination:{
                current_page:0,
                total:0,
                previous_page:0,
                last_page:0,
            },
            //shopify csv import end

            //woocommerce csv import start
            woocommerceHeaders: [
                {
                    name:'Name *',
                    columnName:'name',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Description *',
                    columnName:'description',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:5000,
                        type:'String',
                        final:'String  | 5000 Characters'
                    }
                },
                {
                    name:'Category *',
                    columnName:'category',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Published *',
                    columnName:'isActive',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:500,
                        type:'Boolean',
                        final:'Boolean | True/False'
                    }
                },
                {
                    name:'Featured *',
                    columnName:'isFeatured',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:500,
                        type:'Boolean',
                        final:'Boolean | True/False'
                    }
                },
                {
                    name:'Short Description',
                    columnName:'short_description',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:300,
                        type:'String',
                        final:'String  | 300 Characters'
                    }
                },
                {
                    name:'SEO Title',
                    columnName:'meta_title',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'SEO Description',
                    columnName:'meta_description',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:500,
                        type:'String',
                        final:'String  | 500 Characters'
                    }
                },
                {
                    name:'SEO Keywords',
                    columnName:'keywords',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
            ],
            tempWoocommerceHeaders: [
                {
                    name:'Name *',
                    columnName:'name',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Description *',
                    columnName:'description',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:5000,
                        type:'String',
                        final:'String  | 5000 Characters'
                    }
                },
                {
                    name:'Category *',
                    columnName:'category',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'Published *',
                    columnName:'isActive',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:500,
                        type:'Boolean',
                        final:'Boolean | True/False'
                    }
                },
                {
                    name:'Featured *',
                    columnName:'isFeatured',
                    mappedTo:'',
                    isRequired:'YES',
                    format:{
                        length:500,
                        type:'Boolean',
                        final:'Boolean | True/False'
                    }
                },
                {
                    name:'Short Description',
                    columnName:'short_description',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:300,
                        type:'String',
                        final:'String  | 300 Characters'

                    }
                },
                {
                    name:'SEO Title',
                    columnName:'meta_title',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
                {
                    name:'SEO Description',
                    columnName:'meta_description',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:500,
                        type:'String',
                        final:'String  | 500 Characters'
                    }
                },
                {
                    name:'SEO Keywords',
                    columnName:'keywords',
                    mappedTo:'',
                    isRequired:'NO',
                    format:{
                        length:250,
                        type:'String',
                        final:'String  | 250 Characters'
                    }
                },
            ],
            woocommerceCsvPopup: false,
            woocommerceCsvFile: null,
            woocommerceCsvFileHeaders: [],
            woocommerceCsvFileData: [],
            woocommerceCsvFileProducts: [],
            woocommerceActiveStep: 0,
            woocommerceCsvFileValidationErrors: [],
            woocommerceFinalizedCsvProducts: [],
            woocommerceCurrentPageProducts: null,
            woocommerceCsvDefaultQty: 0,
            woocommerceMainPagination:{
                current_page:0,
                total:0,
                previous_page:0,
                last_page:0,
            },
            //woocommerce csv import end

        }
    },
    props:{

    },
    computed:{
        ...mapGetters({
            settings: "settings_module/settings",
        }),
    },
    watch:{
    },
    methods:{
        openCsvImportModal(platform){
        
            if(platform == 'shopify'){

                this.csvPopUp = true;

                this.csvFile = null;
                this.csvFileHeaders = [];
                this.csvFileData = [];
                this.csvFileProducts = [];

                this.finalizedCsvProducts = [];
                this.csvDefaultQty = 0;

                this.currentPageProducts = null;

                this.mainPagination = {
                    current_page:0,
                    total:0,
                    previous_page:0,
                    last_page:0,
                }

                this.activeStep = 0;

            }else if(platform == 'woocommerce'){

                this.woocommerceCsvPopup = true;

                this.woocommerceCsvFile = null;
                this.woocommerceCsvFileHeaders = [];
                this.woocommerceCsvFileData = [];
                this.woocommerceCsvFileProducts = [];

                this.woocommerceFinalizedCsvProducts = [];
                this.woocommerceCsvDefaultQty = 0;

                this.woocommerceCurrentPageProducts = null;

                this.woocommerceMainPagination = {
                    current_page:0,
                    total:0,
                    previous_page:0,
                    last_page:0,
                }

                this.woocommerceActiveStep = 0;

            }

        },
        closeCsvPopupHandle(platform){

            if(platform == 'shopify'){

                this.csvPopUp = false;

                this.csvFile = null;
                this.csvFileHeaders = [];
                this.csvFileData = [];
                this.csvFileProducts = [];

                this.finalizedCsvProducts = [];
                this.csvDefaultQty = 0;

                this.currentPageProducts = null;

                this.mainPagination = {
                    current_page:0,
                    total:0,
                    previous_page:0,
                    last_page:0,
                }

                this.myHeaders = _.cloneDeep(this.tempProductHeaders);
                this.csvFileValidationErrors = [];

            }else if(platform == 'woocommerce'){

                this.woocommerceCsvPopup = false;

                this.woocommerceCsvFile = null;
                this.woocommerceCsvFileHeaders = [];
                this.woocommerceCsvFileData = [];
                this.woocommerceCsvFileProducts = [];

                this.woocommerceFinalizedCsvProducts = [];
                this.woocommerceCsvDefaultQty = 0;

                this.woocommerceCurrentPageProducts = null;

                this.woocommerceMainPagination = {
                    current_page:0,
                    total:0,
                    previous_page:0,
                    last_page:0,
                }

                this.woocommerceHeaders = _.cloneDeep(this.tempWoocommerceHeaders);
                this.woocommerceCsvFileValidationErrors = [];

            }

        },
        csvPopUpStepHandle(platform,action){

            if(platform == 'shopify'){

                if(action == 'incr'){

                    if((this.activeStep + 1) > 4){

                        this.activeStep = 4;

                        this.csvDataUploadHandle('shopify');

                    }else{

                        if(this.activeStep == 2){

                            this.mappedHeadersValidationsCheck('shopify');

                        }else if(this.activeStep == 3){

                            this.$refs.csvProductsObserver.validate().then((success) => {

                                if(!success){

                                    const errors = Object.entries(this.$refs.csvProductsObserver.errors)
                                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                                    this.$refs.csvProductsObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                                }else{

                                    this.activeStep = 4;

                                }

                            });

                        }else if(this.activeStep == 4){

                            this.csvDataUploadHandle('shopify');

                        }else{

                            this.activeStep = this.activeStep + 1;

                        }

                    }

                }else if(action == 'decr'){

                    if((this.activeStep - 1) < 0){

                        this.activeStep = 0;

                    }else{

                        this.activeStep = this.activeStep - 1;

                    }

                }

            }else if(platform == 'woocommerce'){

                if(action == 'incr'){

                    if((this.woocommerceActiveStep + 1) > 4){

                        this.woocommerceActiveStep = 4;

                        this.csvDataUploadHandle('woocommerce');

                    }else{

                        if(this.woocommerceActiveStep == 2){

                            this.mappedHeadersValidationsCheck('woocommerce');

                        }else if(this.woocommerceActiveStep == 3){

                            this.$refs.woocommerceCsvProductsObserver.validate().then((success) => {

                                if(!success){

                                    const errors = Object.entries(this.$refs.woocommerceCsvProductsObserver.errors)
                                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                                    this.$refs.woocommerceCsvProductsObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                                }else{

                                    this.woocommerceActiveStep = 4;

                                }

                            });

                        }else if(this.woocommerceActiveStep == 4){

                            this.csvDataUploadHandle('woocommerce');

                        }else{

                            this.woocommerceActiveStep = this.woocommerceActiveStep + 1;

                        }

                    }

                }else if(action == 'decr'){

                    if((this.woocommerceActiveStep - 1) < 0){

                        this.woocommerceActiveStep = 0;

                    }else{

                        this.woocommerceActiveStep = this.woocommerceActiveStep - 1;

                    }

                }

            }

        },
        async csvFileUploadHandle(platform,e){

            if(platform == 'shopify'){

                if(e.target.files.length > 0){

                    if(e.target.files[0].type == 'text/csv'){

                        let loader = Loading.service({
                            fullscreen: true,
                            text: 'Extracting products from the file. Please Wait!',
                        });

                        new Promise((resolve, reject) => {

                            this.csvFile = e.target.files[0];

                            const reader = new FileReader();

                            reader.onload = () => {
                            
                                const csvString = reader.result;

                                this.$papa.parse(csvString, {
                                    header: true,
                                    skipEmptyLines: true,
                                    complete: (results) => {

                                        this.csvFileHeaders = results.meta.fields;

                                        const keyValuePairData = results.data.map((row) => {
                                            
                                            const obj = {};

                                            for(const key in row){

                                                if(row.hasOwnProperty(key)){

                                                    obj[key] = row[key];

                                                }

                                            }

                                            return obj;
                                        
                                        });

                                        this.csvFileData = keyValuePairData;

                                        let tempCsvProducts = [];

                                        for(let i = 0; i < keyValuePairData.length; i++){

                                            if(keyValuePairData[i]['Published'] != ''){
                                            
                                                let obj = {
                                                    product:keyValuePairData[i],
                                                    variants:[],
                                                    images:[keyValuePairData[i]['Image Src']],
                                                }

                                                tempCsvProducts.push(obj)

                                            }else{

                                                for(let j = 0; j < tempCsvProducts.length; j++){

                                                    if((tempCsvProducts[j].product.Handle === keyValuePairData[i].Handle)){

                                                        if((tempCsvProducts[j].product['Option1 Name'] != '') && 
                                                        (keyValuePairData[i]['Option1 Value'] != '')){

                                                            tempCsvProducts[j].variants.push(keyValuePairData[i]);

                                                        }else if((tempCsvProducts[j].product['Option2 Name'] != '') && 
                                                        (keyValuePairData[i]['Option2 Value'] != '')){

                                                            tempCsvProducts[j].variants.push(keyValuePairData[i]);

                                                        }else if((tempCsvProducts[j].product['Option3 Name'] != '') && 
                                                        (keyValuePairData[i]['Option3 Value'] != '')){

                                                            tempCsvProducts[j].variants.push(keyValuePairData[i]);

                                                        }

                                                        if(keyValuePairData[i]['Image Src'] != '' && 
                                                        !tempCsvProducts[j].images.includes(keyValuePairData[i]['Image Src'])){

                                                            tempCsvProducts[j].images.push(keyValuePairData[i]['Image Src']);

                                                        }

                                                        if(keyValuePairData[i]['Variant Image'] && keyValuePairData[i]['Variant Image'] != '' &&
                                                        !tempCsvProducts[j].images.includes(keyValuePairData[i]['Variant Image'])){

                                                            tempCsvProducts[j].images.push(keyValuePairData[i]['Variant Image']);

                                                        }

                                                    }

                                                }
                                                
                                            }

                                        }

                                        this.csvFileProducts = [];

                                        this.csvFileProducts = _.cloneDeep(tempCsvProducts);

                                        resolve({
                                            message: 'Products have been successfully extracted from the provided file.',
                                        });

                                    },
                                    error: (error) => {

                                        reject({
                                            message: "Something went wrong while processing the data provided in CSV file.",
                                            error: error,
                                        });

                                    },

                                });

                            };

                            reader.readAsText(this.csvFile);

                        }).then((result) => {

                            loader.close();

                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: result.message,
                            });

                        }).catch((error) => {

                            this.csvFile = null;

                            this.csvFileHeaders = [];

                            this.csvFileData = [];

                            this.csvFileProducts = [];

                            loader.close();

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        });

                    }else{

                        this.$refs.shopifyCsvUploader.value = '';

                        this.$notify({
                            type: "error",
                            title: "Alert",
                            message: "Uploaded file must be in CSV format.",
                        }); 

                    }

                }

            }else if(platform == 'woocommerce'){

                if(e.target.files.length > 0){

                    if(e.target.files[0].type == 'text/csv'){

                        let loader = Loading.service({
                            fullscreen: true,
                            text: 'Extracting products from the file. Please Wait!',
                        });

                        new Promise((resolve, reject) => {
                            
                            this.woocommerceCsvFile = e.target.files[0];

                            const reader = new FileReader();

                            reader.onload = () => {
                            
                                const csvString = reader.result;

                                this.$papa.parse(csvString, {
                                    header: true,
                                    skipEmptyLines: true,
                                    complete: (results) => {

                                        this.woocommerceCsvFileHeaders = results.meta.fields;

                                        const keyValuePairData = results.data.map((row) => {

                                            const obj = {};

                                            for(const key in row){

                                                if(row.hasOwnProperty(key)){

                                                    obj[key] = row[key];

                                                }

                                            }

                                            return obj;
                                            
                                        });

                                        this.woocommerceCsvFileData = keyValuePairData;

                                        let tempCsvProducts = [];

                                        for(let i = 0; i < keyValuePairData.length; i++){

                                            if(keyValuePairData[i]['Type']){

                                                if(keyValuePairData[i]['Type'].includes('simple')){

                                                    let tempProductImages = [];

                                                    if(keyValuePairData[i]['Images']){

                                                        let productImages = keyValuePairData[i]['Images'].split(",");

                                                        tempProductImages = _.cloneDeep(productImages);

                                                    }

                                                    let obj = {
                                                        product:keyValuePairData[i],
                                                        variants:[],
                                                        images: tempProductImages,
                                                    }

                                                    tempCsvProducts.push(obj);

                                                }else{

                                                    if(keyValuePairData[i]['Type'] == 'variable'){

                                                        let tempProductImages = [];

                                                        if(keyValuePairData[i]['Images']){

                                                            let productImages = keyValuePairData[i]['Images'].split(",");

                                                            tempProductImages = _.cloneDeep(productImages);

                                                        }

                                                        let obj = {
                                                            product:keyValuePairData[i],
                                                            variants:[],
                                                            images: tempProductImages,
                                                        }

                                                        tempCsvProducts.push(obj);

                                                    }else if(keyValuePairData[i]['Type'] == 'variation'){

                                                        if(keyValuePairData[i]['Parent'].includes('id:')){

                                                            let parentId = keyValuePairData[i]['Parent'].split(":");

                                                            let parentProductIndex = tempCsvProducts.findIndex((listedProduct) => listedProduct.product['ID'] == parentId[1]);

                                                            if((tempCsvProducts[parentProductIndex].product['Attribute 1 name'] != '') &&
                                                            (keyValuePairData[i]['Attribute 1 value(s)'] != '')){

                                                                tempCsvProducts[parentProductIndex].variants.push(keyValuePairData[i]);

                                                            }else if((tempCsvProducts[parentProductIndex].product['Attribute 2 name'] != '') && 
                                                            (keyValuePairData[i]['Attribute 2 value(s)'] != '')){

                                                                tempCsvProducts[parentProductIndex].variants.push(keyValuePairData[i]);

                                                            }else if((tempCsvProducts[parentProductIndex].product['Attribute 3 name'] != '') && 
                                                            (keyValuePairData[i]['Attribute 3 value(s)'] != '')){

                                                                tempCsvProducts[parentProductIndex].variants.push(keyValuePairData[i]);

                                                            }

                                                            let tempProductImages = [];

                                                            if(keyValuePairData[i]['Images']){

                                                                let productImages = keyValuePairData[i]['Images'].split(",");

                                                                tempProductImages = _.cloneDeep(productImages);

                                                                for(let k = 0; k < tempProductImages.length; k++){

                                                                    if(!tempCsvProducts[parentProductIndex].images.includes(tempProductImages[k])){

                                                                        tempCsvProducts[parentProductIndex].images.push(tempProductImages[k]);

                                                                    }

                                                                }
                                                            
                                                            }

                                                        }else{

                                                            let parentProductIndex = tempCsvProducts.findIndex((listedProduct) => listedProduct.product['SKU'] == keyValuePairData[i]['Parent']);

                                                            if((tempCsvProducts[parentProductIndex].product['Attribute 1 name'] != '') && 
                                                            (keyValuePairData[i]['Attribute 1 value(s)'] != '')){

                                                                tempCsvProducts[parentProductIndex].variants.push(keyValuePairData[i]);

                                                            }else if((tempCsvProducts[parentProductIndex].product['Attribute 2 name'] != '') && 
                                                            (keyValuePairData[i]['Attribute 2 value(s)'] != '')){

                                                                tempCsvProducts[parentProductIndex].variants.push(keyValuePairData[i]);

                                                            }else if((tempCsvProducts[parentProductIndex].product['Attribute 3 name'] != '') && 
                                                            (keyValuePairData[i]['Attribute 3 value(s)'] != '')){

                                                                tempCsvProducts[parentProductIndex].variants.push(keyValuePairData[i]);

                                                            }

                                                            let tempProductImages = [];

                                                            if(keyValuePairData[i]['Images']){

                                                                let productImages = keyValuePairData[i]['Images'].split(",");

                                                                tempProductImages = _.cloneDeep(productImages);

                                                                for(let k = 0; k < tempProductImages.length; k++){

                                                                    if(!tempCsvProducts[parentProductIndex].images.includes(tempProductImages[k])){

                                                                        tempCsvProducts[parentProductIndex].images.push(tempProductImages[k]);

                                                                    }

                                                                }
                                                            
                                                            }

                                                        }

                                                    }

                                                }

                                            }else{

                                                reject({
                                                    message: "Something went wrong while processing the data provided in CSV file.",
                                                    error: null,
                                                });

                                                break;

                                            }

                                        }

                                        this.woocommerceCsvFileProducts = [];

                                        this.woocommerceCsvFileProducts = _.cloneDeep(tempCsvProducts);

                                        resolve({
                                            message: 'Products have been successfully extracted from the provided file.',
                                        });

                                    },
                                    error: (error) => {

                                        reject({
                                            message: "Something went wrong while processing the data provided in CSV file.",
                                            error: error,
                                        });

                                    },

                                });

                            };

                            reader.readAsText(this.woocommerceCsvFile);

                        }).then((result) => {

                            loader.close();

                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: result.message,
                            });

                        }).catch((error) => {

                            this.woocommerceCsvFile = null;

                            this.woocommerceCsvFileHeaders = [];

                            this.woocommerceCsvFileData = [];

                            this.woocommerceCsvFileProducts = [];

                            if(this.$refs.woocommerceCsvUploader){

                                this.$refs.woocommerceCsvUploader.value = '';

                            }

                            loader.close();

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        });

                        return 

                    }else{

                        this.$refs.woocommerceCsvUploader.value = ''

                        this.$notify({
                            type: "error",
                            title: "Alert",
                            message: "Uploaded file must be in CSV format.",
                        }); 

                    }

                }

            }

        },
        csvRemovalHandle(platform){

            if(platform == 'shopify'){

                this.csvFile = null;
                this.csvFileHeaders = [];
                this.csvFileData = [];
                this.csvFileProducts = [];

                this.finalizedCsvProducts = [];

                this.currentPageProducts = null;

                this.mainPagination = {
                    current_page:0,
                    total:0,
                    previous_page:0,
                    last_page:0,
                }

                this.myHeaders = _.cloneDeep(this.tempProductHeaders);
                this.csvFileValidationErrors = [];

            }else if(platform == 'woocommerce'){

                this.woocommerceCsvFile = null;
                this.woocommerceCsvFileHeaders = [];
                this.woocommerceCsvFileData = [];
                this.woocommerceCsvFileProducts = [];

                this.woocommerceFinalizedCsvProducts = [];

                this.woocommerceCurrentPageProducts = null;

                this.woocommerceMainPagination = {
                    current_page:0,
                    total:0,
                    previous_page:0,
                    last_page:0,
                }

                this.woocommerceHeaders = _.cloneDeep(this.tempWoocommerceHeaders);
                this.woocommerceCsvFileValidationErrors = [];

            }

        },
        mappedHeadersValidationsCheck(platform){

            if(platform == 'shopify'){

                if(this.finalizedCsvProducts.length > 0){

                    this.csvFileProductsProcessing('shopify');

                }else{

                    if(this.myHeaders.some((header) => header.mappedTo == '' && header.isRequired == 'YES')){

                        this.$notify({
                            type: "error",
                            title: "Mapping Required",
                            message: "Kindly map all the required headers before proceeding.",
                        });

                        return

                    }

                    let loader = Loading.service({
                        fullscreen: true,
                        text: 'Validating products based on the mapped headers. Please Wait!',
                    });

                    new Promise((resolve, reject) => {

                        let validationErrors = [];

                        for(let i = 0 ; i < this.csvFileData.length ; i++){

                            for(let j = 0 ; j < this.myHeaders.length ; j++){

                                const mappedTo = this.myHeaders[j].mappedTo;
                                const value = this.csvFileData[i][mappedTo];

                                if(value == '' && this.myHeaders[j].isRequired == 'YES' && this.csvFileData[i]['Published'] != ''){

                                    let error = `Value of '${mappedTo}' is missing at the row ${i + 2} of the file`

                                    validationErrors.push(error);

                                }else if(value && this.myHeaders[j].format.length && (value.length > this.myHeaders[j].format.length)){

                                    let error = `Value of '${mappedTo}' exceeds the length at the row ${i + 2} of the file`

                                    validationErrors.push(error);

                                }else if(value && this.myHeaders[j].format.type == 'Boolean'){
                                    
                                    if(value == 'TRUE' || value == 'true' || value == 'FALSE' || value == 'false'){

                                    }else{

                                        let error = `Value of '${mappedTo}' should be 'TRUE' or 'FALSE' at the row ${i + 2} of the file`
                                        
                                        validationErrors.push(error);

                                    }

                                }

                            }

                        }

                        if(validationErrors.length > 0){

                            reject({
                                message: 'validation errors detected',
                                validationErrors: validationErrors,
                            });

                        }else{

                            resolve({
                                message: 'validation errors not detected',
                                validationErrors: null,
                            });

                        }

                    }).then((result) => {

                        loader.close();

                        this.csvFileProductsProcessing('shopify');

                    }).catch((error) => {

                        loader.close();

                        if(error.validationErrors && error.validationErrors.length > 0){

                            this.csvFileValidationErrors = error.validationErrors;

                        }

                    })

                }

            }else if(platform == 'woocommerce'){

                if(this.woocommerceFinalizedCsvProducts.length > 0){

                    this.csvFileProductsProcessing('woocommerce');

                }else{

                    if(this.woocommerceHeaders.some((header) => header.mappedTo == '' && header.isRequired == 'YES')){

                        this.$notify({
                            type: "error",
                            title: "Mapping Required",
                            message: "Kindly map all the required headers before proceeding.",
                        });

                        return

                    }

                    let loader = Loading.service({
                        fullscreen: true,
                        text: 'Validating products based on the mapped headers. Please Wait!',
                    });

                    new Promise((resolve, reject) => {

                        let validationErrors = [];

                        for(let i = 0 ; i < this.woocommerceCsvFileData.length ; i++){

                            for(let j = 0 ; j < this.woocommerceHeaders.length ; j++){

                                const mappedTo = this.woocommerceHeaders[j].mappedTo;
                                const value = this.woocommerceCsvFileData[i][mappedTo];

                                if(this.woocommerceCsvFileData[i]['Type'].includes('simple') || this.woocommerceCsvFileData[i]['Type'] == 'variable'){

                                    if(value == '' && this.woocommerceHeaders[j].isRequired == 'YES' && this.woocommerceCsvFileData[i]['Published'] != ''){

                                        let error = `Value of '${mappedTo}' is missing at the row ${i + 2} of the file`

                                        validationErrors.push(error);

                                    }else if(value && this.woocommerceHeaders[j].format.length && (value.length > this.woocommerceHeaders[j].format.length)){

                                        let error = `Value of '${mappedTo}' exceeds the length at the row ${i + 2} of the file`

                                        validationErrors.push(error);

                                    }else if(value && this.woocommerceHeaders[j].format.type == 'Boolean'){

                                        if(value == 1 || value == 0){

                                        }else{

                                            let error = `Value of '${mappedTo}' should be '1' or '0' at the row ${i + 2} of the file`

                                            validationErrors.push(error);

                                        }

                                    }

                                }

                            }

                        }

                        if(validationErrors.length > 0){

                            reject({
                                message: 'validation errors detected',
                                validationErrors: validationErrors,
                            });

                        }else{

                            resolve({
                                message: 'validation errors not detected',
                                validationErrors: null,
                            });

                        }

                    }).then((result) => {

                        loader.close();

                        this.csvFileProductsProcessing('woocommerce');

                    }).catch((error) => {

                        loader.close();

                        if(error.validationErrors && error.validationErrors.length > 0){

                            this.woocommerceCsvFileValidationErrors = error.validationErrors;

                        }

                    });

                }

            }

        },
        csvFileProductsProcessing(platform){

            if(platform == 'shopify'){

                if(this.finalizedCsvProducts.length > 0){

                    this.activeStep = 3;

                }else{

                    let productProcessingloader = Loading.service({
                        fullscreen: true,
                        text: 'Processing products based on the mapped headers. Please Wait!',
                    });

                    new Promise((resolve, reject) => {

                        let finalizedProducts = [];

                        for(let i = 0 ; i < this.csvFileProducts.length ; i++){
                        
                            let csvProduct = {};
                            
                            csvProduct['id'] = uuidv4();
                        
                            for(let j = 0 ; j < this.myHeaders.length ; j++){

                                const mappedTo = this.myHeaders[j].mappedTo;

                                const value = this.csvFileProducts[i].product[mappedTo];

                                if(value){

                                    if(this.myHeaders[j].columnName == 'isActive'){

                                        if(value == 'TRUE' || value == 'true'){

                                            csvProduct[this.myHeaders[j].columnName] = 1

                                        }else{

                                            csvProduct[this.myHeaders[j].columnName] = 0

                                        }

                                    }else{

                                        csvProduct[this.myHeaders[j].columnName] = value

                                    }

                                }else{

                                    csvProduct[this.myHeaders[j].columnName] = null

                                }

                            }

                            csvProduct['images'] = this.csvFileProducts[i].images

                            //if product have variants
                            if(this.csvFileProducts[i].variants.length > 0){

                                let processedVariants = [];

                                //when 'Option1 Name', 'Option2 Name' & 'Option3 Name' are present
                                if(this.csvFileProducts[i].product['Option1 Name'] != '' && 
                                this.csvFileProducts[i].product['Option2 Name'] != '' &&
                                this.csvFileProducts[i].product['Option3 Name'] != ''){

                                    //product info copying as variant
                                    this.csvFileProducts[i].variants.unshift(this.csvFileProducts[i].product);

                                    //getting option1, option2 & option3 values
                                    let option1Values = [];
                                    let option2Values = [];
                                    let option3Values = [];

                                    for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                        if((this.csvFileProducts[i].variants[k]['Option1 Value'] != '') && 
                                        (!option1Values.includes(this.csvFileProducts[i].variants[k]['Option1 Value']))){

                                            option1Values.push(this.csvFileProducts[i].variants[k]['Option1 Value']);

                                        }

                                        if((this.csvFileProducts[i].variants[k]['Option2 Value'] != '') && 
                                        (!option2Values.includes(this.csvFileProducts[i].variants[k]['Option2 Value']))){

                                            option2Values.push(this.csvFileProducts[i].variants[k]['Option2 Value']);

                                        }

                                        if((this.csvFileProducts[i].variants[k]['Option3 Value'] != '') && 
                                        (!option3Values.includes(this.csvFileProducts[i].variants[k]['Option3 Value']))){

                                            option3Values.push(this.csvFileProducts[i].variants[k]['Option3 Value']);

                                        }

                                    }

                                    //option creating
                                    let productOptions = [
                                        {
                                            name: this.csvFileProducts[i].product['Option1 Name'],
                                            values: [],
                                        },
                                        {
                                            name: this.csvFileProducts[i].product['Option2 Name'],
                                            values: [],
                                        },
                                        {
                                            name: this.csvFileProducts[i].product['Option3 Name'],
                                            values: [],
                                        },
                                    ]

                                    if(option1Values.length > 0 && option2Values.length == 0 && option3Values.length == 0){

                                        option1Values.forEach((optValue1) => {

                                            let tempVariant = {
                                                name: optValue1,
                                                sku: '',
                                                weight: '',
                                                price: '',
                                                comparedPrice: '',
                                                qty: 0,
                                                barcode: '',
                                                value1: optValue1,
                                            }

                                            processedVariants.push(tempVariant);

                                        });

                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value']){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[0].values.includes(this.csvFileProducts[i].variants[k]['Option1 Value'])){

                                                productOptions[0].values.push(this.csvFileProducts[i].variants[k]['Option1 Value']);

                                            }

                                        }

                                    }else if(option1Values.length == 0 && option2Values.length > 0 && option3Values.length == 0){

                                        option2Values.forEach((optValue2) => {

                                            let tempVariant = {
                                                name: optValue2,
                                                sku: '',
                                                weight: '',
                                                price: '',
                                                comparedPrice: '',
                                                qty: 0,
                                                barcode: '',
                                                value1: optValue2,
                                            }

                                            processedVariants.push(tempVariant);

                                        });

                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.csvFileProducts[i].variants[k]['Option2 Value']){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[1].values.includes(this.csvFileProducts[i].variants[k]['Option2 Value'])){

                                                productOptions[1].values.push(this.csvFileProducts[i].variants[k]['Option2 Value']);

                                            }

                                        }

                                    }else if(option1Values.length == 0 && option2Values.length == 0 && option3Values.length > 0){

                                        option3Values.forEach((optValue3) => {

                                            let tempVariant = {
                                                name: optValue3,
                                                sku: '',
                                                weight: '',
                                                price: '',
                                                comparedPrice: '',
                                                qty: 0,
                                                barcode: '',
                                                value1: optValue3,
                                            }

                                            processedVariants.push(tempVariant);

                                        });

                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.csvFileProducts[i].variants[k]['Option3 Value']){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[2].values.includes(this.csvFileProducts[i].variants[k]['Option3 Value'])){

                                                productOptions[2].values.push(this.csvFileProducts[i].variants[k]['Option3 Value']);

                                            }

                                        }

                                    }else if(option1Values.length > 0 && option2Values.length > 0 && option3Values.length == 0){

                                        option1Values.forEach((optValue1) =>{

                                            option2Values.forEach((optValue2) =>{
                                                
                                                let tempVariant = {
                                                    name: optValue1 + '-' + optValue2,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    comparedPrice: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    value1: optValue1,
                                                    value2: optValue2,
                                                }

                                                processedVariants.push(tempVariant);

                                            });

                                        });

                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value'] && 
                                                pVariant.value2 == this.csvFileProducts[i].variants[k]['Option2 Value']){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[0].values.includes(this.csvFileProducts[i].variants[k]['Option1 Value'])){

                                                productOptions[0].values.push(this.csvFileProducts[i].variants[k]['Option1 Value']);

                                            }

                                            if(!productOptions[1].values.includes(this.csvFileProducts[i].variants[k]['Option2 Value'])){

                                                productOptions[1].values.push(this.csvFileProducts[i].variants[k]['Option2 Value']);

                                            }

                                        }

                                    }else if(option1Values.length > 0 && option2Values.length == 0 && option2Values.length > 0){

                                        option1Values.forEach((optValue1) =>{

                                            option3Values.forEach((optValue3) =>{
                                                
                                                let tempVariant = {
                                                    name: optValue1 + '-' + optValue3,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    comparedPrice: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    value1: optValue1,
                                                    value2: optValue3,
                                                }

                                                processedVariants.push(tempVariant);

                                            });

                                        });

                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value'] && 
                                                pVariant.value2 == this.csvFileProducts[i].variants[k]['Option3 Value']){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[0].values.includes(this.csvFileProducts[i].variants[k]['Option1 Value'])){

                                                productOptions[0].values.push(this.csvFileProducts[i].variants[k]['Option1 Value']);

                                            }

                                            if(!productOptions[2].values.includes(this.csvFileProducts[i].variants[k]['Option3 Value'])){

                                                productOptions[2].values.push(this.csvFileProducts[i].variants[k]['Option3 Value']);

                                            }

                                        }

                                    }else if(option1Values.length == 0 && option2Values.length > 0 && option2Values.length > 0){

                                        option2Values.forEach((optValue2) =>{

                                            option3Values.forEach((optValue3) =>{
                                                
                                                let tempVariant = {
                                                    name: optValue2 + '-' + optValue3,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    comparedPrice: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    value1: optValue2,
                                                    value2: optValue3,
                                                }

                                                processedVariants.push(tempVariant);

                                            });

                                        });

                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.csvFileProducts[i].variants[k]['Option2 Value'] && 
                                                pVariant.value2 == this.csvFileProducts[i].variants[k]['Option3 Value']){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[1].values.includes(this.csvFileProducts[i].variants[k]['Option2 Value'])){

                                                productOptions[1].values.push(this.csvFileProducts[i].variants[k]['Option2 Value']);

                                            }

                                            if(!productOptions[2].values.includes(this.csvFileProducts[i].variants[k]['Option3 Value'])){

                                                productOptions[2].values.push(this.csvFileProducts[i].variants[k]['Option3 Value']);

                                            }

                                        }

                                    }else if(option1Values.length > 0 && option2Values.length > 0 && option2Values.length > 0){

                                        option1Values.forEach((optValue1) =>{

                                            option2Values.forEach((optValue2) =>{

                                                option3Values.forEach((optValue3) =>{

                                                    let tempVariant = {
                                                        name: optValue1 + '-' + optValue2 + '-' + optValue3,
                                                        sku: '',
                                                        weight: '',
                                                        price: '',
                                                        comparedPrice: '',
                                                        qty: 0,
                                                        barcode: '',
                                                        value1: optValue1,
                                                        value2: optValue2,
                                                        value3: optValue3,
                                                    }

                                                    processedVariants.push(tempVariant);

                                                });

                                            });

                                        });

                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if((pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value']) &&
                                                (pVariant.value2 != this.csvFileProducts[i].variants[k]['Option2 Value']) &&
                                                (pVariant.value3 != this.csvFileProducts[i].variants[k]['Option3 Value'])){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }else if((pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value']) &&
                                                (pVariant.value2 == this.csvFileProducts[i].variants[k]['Option2 Value']) &&
                                                (pVariant.value3 != this.csvFileProducts[i].variants[k]['Option3 Value'])){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }else if((pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value']) &&
                                                (pVariant.value2 != this.csvFileProducts[i].variants[k]['Option2 Value']) &&
                                                (pVariant.value3 == this.csvFileProducts[i].variants[k]['Option3 Value'])){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }else if((pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value']) && 
                                                (pVariant.value2 == this.csvFileProducts[i].variants[k]['Option2 Value']) && 
                                                (pVariant.value3 == this.csvFileProducts[i].variants[k]['Option3 Value'])){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[0].values.includes(this.csvFileProducts[i].variants[k]['Option1 Value'])){

                                                productOptions[0].values.push(this.csvFileProducts[i].variants[k]['Option1 Value']);

                                            }

                                            if(!productOptions[1].values.includes(this.csvFileProducts[i].variants[k]['Option2 Value'])){

                                                productOptions[1].values.push(this.csvFileProducts[i].variants[k]['Option2 Value']);

                                            }

                                            if(!productOptions[2].values.includes(this.csvFileProducts[i].variants[k]['Option3 Value'])){

                                                productOptions[2].values.push(this.csvFileProducts[i].variants[k]['Option3 Value']);

                                            }

                                        }

                                    }

                                    for(let l = 0 ; l < processedVariants.length ; l++){

                                        csvProduct['variant'] = _.cloneDeep(processedVariants[l]);

                                        csvProduct['options'] = _.cloneDeep(productOptions);

                                        csvProduct['product_type'] = 'variable'

                                        let tempCsvProduct = _.cloneDeep(csvProduct);

                                        finalizedProducts.push(tempCsvProduct);

                                    }

                                }

                                //when 'Option1 Name' & 'Option2 Name' are present
                                if(this.csvFileProducts[i].product['Option1 Name'] != '' &&
                                this.csvFileProducts[i].product['Option2 Name'] != '' &&
                                this.csvFileProducts[i].product['Option3 Name'] == ''){

                                    //product info copying as variant
                                    this.csvFileProducts[i].variants.unshift(this.csvFileProducts[i].product);

                                    //getting option1 & option2 values
                                    let option1Values = [];
                                    let option2Values = [];

                                    for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                        if((this.csvFileProducts[i].variants[k]['Option1 Value'] != '') &&
                                        (!option1Values.includes(this.csvFileProducts[i].variants[k]['Option1 Value']))){

                                            option1Values.push(this.csvFileProducts[i].variants[k]['Option1 Value']);

                                        }

                                        if((this.csvFileProducts[i].variants[k]['Option2 Value'] != '') && 
                                        (!option2Values.includes(this.csvFileProducts[i].variants[k]['Option2 Value']))){

                                            option2Values.push(this.csvFileProducts[i].variants[k]['Option2 Value']);

                                        }

                                    }

                                    //option creating
                                    let productOptions = [
                                        {
                                            name: this.csvFileProducts[i].product['Option1 Name'],
                                            values: [],
                                        },
                                        {
                                            name: this.csvFileProducts[i].product['Option2 Name'],
                                            values: [],
                                        },
                                    ]

                                    if(option1Values.length > 0 && option2Values.length == 0){

                                        option1Values.forEach((optValue1) => {

                                            let tempVariant = {
                                                name: optValue1,
                                                sku: '',
                                                weight: '',
                                                price: '',
                                                comparedPrice: '',
                                                qty: 0,
                                                barcode: '',
                                                value1: optValue1,
                                            }

                                            processedVariants.push(tempVariant);

                                        });
                                        
                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value']){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[0].values.includes(this.csvFileProducts[i].variants[k]['Option1 Value'])){

                                                productOptions[0].values.push(this.csvFileProducts[i].variants[k]['Option1 Value']);

                                            }

                                        }

                                    }else if(option1Values.length == 0 && option2Values.length > 0){

                                        option2Values.forEach((optValue2) => {

                                            let tempVariant = {
                                                name: optValue2,
                                                sku: '',
                                                weight: '',
                                                price: '',
                                                comparedPrice: '',
                                                qty: 0,
                                                barcode: '',
                                                value1: optValue2,
                                            }

                                            processedVariants.push(tempVariant);

                                        })

                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.csvFileProducts[i].variants[k]['Option2 Value']){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            })

                                            //pushing option values
                                            if(!productOptions[1].values.includes(this.csvFileProducts[i].variants[k]['Option2 Value'])){

                                                productOptions[1].values.push(this.csvFileProducts[i].variants[k]['Option2 Value']);

                                            }

                                        }

                                    }else{

                                        option1Values.forEach((optValue1) => {

                                            option2Values.forEach((optValue2) =>{

                                                let tempVariant = {
                                                    name: optValue1 + '-' + optValue2,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    comparedPrice: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    value1: optValue1,
                                                    value2: optValue2,
                                                }

                                                processedVariants.push(tempVariant);

                                            });

                                        });

                                        for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if((pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value']) && 
                                                (pVariant.value2 != this.csvFileProducts[i].variants[k]['Option2 Value'])){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }else if((pVariant.value1 != this.csvFileProducts[i].variants[k]['Option1 Value']) && 
                                                (pVariant.value2 == this.csvFileProducts[i].variants[k]['Option2 Value'])){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }else if((pVariant.value1 == this.csvFileProducts[i].variants[k]['Option1 Value']) && 
                                                (pVariant.value2 == this.csvFileProducts[i].variants[k]['Option2 Value'])){

                                                    pVariant.sku = this.csvFileProducts[i].variants[k]['Variant SKU'] ?
                                                    this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                                    this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0;

                                                    pVariant.weight = this.csvFileProducts[i].variants[k]['Variant Grams'];

                                                    pVariant.price = this.csvFileProducts[i].variants[k]['Variant Price'];

                                                    pVariant.comparedPrice = this.csvFileProducts[i].variants[k]['Variant Compare At Price'];

                                                    pVariant.barcode = this.csvFileProducts[i].variants[k]['Variant Barcode'];

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[0].values.includes(this.csvFileProducts[i].variants[k]['Option1 Value'])){

                                                productOptions[0].values.push(this.csvFileProducts[i].variants[k]['Option1 Value']);

                                            }

                                            if(!productOptions[1].values.includes(this.csvFileProducts[i].variants[k]['Option2 Value'])){

                                                productOptions[1].values.push(this.csvFileProducts[i].variants[k]['Option2 Value']);

                                            }

                                        }

                                    }

                                    for(let l = 0 ; l < processedVariants.length ; l++){

                                        csvProduct['variant'] = _.cloneDeep(processedVariants[l]);

                                        csvProduct['options'] = _.cloneDeep(productOptions);

                                        csvProduct['product_type'] = 'variable'

                                        let tempCsvProduct = _.cloneDeep(csvProduct);

                                        finalizedProducts.push(tempCsvProduct);

                                    }

                                }

                                //when only 'Option1 Name' is present
                                if(this.csvFileProducts[i].product['Option1 Name'] != '' && 
                                this.csvFileProducts[i].product['Option2 Name'] == '' &&
                                this.csvFileProducts[i].product['Option3 Name'] == ''){

                                    //variable product info as a variant
                                    let tempProductAsVariant = {
                                        name: this.csvFileProducts[i].product['Option1 Value'],
                                        
                                        sku: this.csvFileProducts[i].product['Variant SKU'] ? 
                                        this.csvFileProducts[i].product['Variant SKU'] : 
                                        this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${i}`,

                                        weight: this.csvFileProducts[i].product['Variant Grams'],
                                        price: this.csvFileProducts[i].product['Variant Price'],
                                        comparedPrice: this.csvFileProducts[i].product['Variant Compare At Price'],
                                        qty: this.csvFileProducts[i].product['Variant Inventory Qty'] ? this.csvFileProducts[i].product['Variant Inventory Qty'] : 0,
                                        barcode: this.csvFileProducts[i].product['Variant Barcode'],
                                        value1: this.csvFileProducts[i].product['Option1 Value'],
                                    }

                                    processedVariants.push(tempProductAsVariant);

                                    //variable product variants info
                                    for(let k = 0 ; k < this.csvFileProducts[i].variants.length ; k++){
                                        
                                        let tempVariant = {
                                            name: this.csvFileProducts[i].variants[k]['Option1 Value'],
                                            sku: this.csvFileProducts[i].variants[k]['Variant SKU'] ? 
                                            this.csvFileProducts[i].variants[k]['Variant SKU'] :
                                            this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${k}`,
                                            weight: this.csvFileProducts[i].variants[k]['Variant Grams'],
                                            price: this.csvFileProducts[i].variants[k]['Variant Price'],
                                            comparedPrice: this.csvFileProducts[i].variants[k]['Variant Compare At Price'],
                                            qty: this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] ? 
                                            this.csvFileProducts[i].variants[k]['Variant Inventory Qty'] : 0,
                                            barcode: this.csvFileProducts[i].variants[k]['Variant Barcode'],
                                            value1: this.csvFileProducts[i].variants[k]['Option1 Value'],
                                        }

                                        processedVariants.push(tempVariant);
                                    
                                    }

                                    //option creating
                                    let productOptions = [
                                        {
                                            name: this.csvFileProducts[i].product['Option1 Name'],
                                            values: [],
                                        }
                                    ]

                                    //appending variants info in formData (array method)
                                    for(let l = 0 ; l < processedVariants.length ; l++){
                                        
                                        productOptions[0].values.push(processedVariants[l].name);

                                    }

                                    for(let l = 0 ; l < processedVariants.length ; l++){

                                        csvProduct['variant'] = _.cloneDeep(processedVariants[l]);

                                        csvProduct['options'] = _.cloneDeep(productOptions);

                                        csvProduct['product_type'] = 'variable'

                                        let tempCsvProduct = _.cloneDeep(csvProduct);

                                        finalizedProducts.push(tempCsvProduct);

                                    }

                                }

                            }else{

                                //simple product info as variant
                                let tempProductAsVariant = {
                                    name: this.csvFileProducts[i].product['Title'],
                                    sku: this.csvFileProducts[i].product['Variant SKU'] ? 
                                    this.csvFileProducts[i].product['Variant SKU'] : 
                                    this.csvFileProducts[i].product['Title'].toLowerCase().replace(/\s+/g, '-') + `-${i}`,
                                    weight: this.csvFileProducts[i].product['Variant Grams'],
                                    price: this.csvFileProducts[i].product['Variant Price'],
                                    comparedPrice: this.csvFileProducts[i].product['Variant Compare At Price'],
                                    qty: this.csvFileProducts[i].product['Variant Inventory Qty'] ? this.csvFileProducts[i].product['Variant Inventory Qty'] : 0,
                                    barcode: this.csvFileProducts[i].product['Variant Barcode'],
                                    value1: this.csvFileProducts[i].product['Title'],
                                }

                                //option creating
                                let productOptions = [
                                    {
                                        name: this.csvFileProducts[i].product['Title'],
                                        values: [],
                                    }
                                ]
                                
                                csvProduct['variant'] = tempProductAsVariant

                                csvProduct['options'] = productOptions

                                csvProduct['product_type'] = 'simple'

                                finalizedProducts.push(csvProduct);

                            }

                        }

                        const perPageItems = 20;

                        const paginatedFinalizedProducts = [];

                        for(let i = 0; i < finalizedProducts.length; i += perPageItems){

                            const chunk = finalizedProducts.slice(i, i + perPageItems);

                            paginatedFinalizedProducts.push({
                                page_no: Math.floor(i / perPageItems) + 1,
                                products: chunk
                            });

                        }

                        this.finalizedCsvProducts = _.cloneDeep(paginatedFinalizedProducts);

                        this.currentPageProducts = _.cloneDeep(this.finalizedCsvProducts[0]);

                        this.mainPagination = {
                            current_page: 1,
                            total: this.finalizedCsvProducts.length,
                            previous_page: 0,
                            last_page: paginatedFinalizedProducts[paginatedFinalizedProducts.length - 1].page_no,
                        }

                        resolve({
                            message:"The products have been successfully processed and are ready to be uploaded.",
                        });

                    }).then((result) => {

                        productProcessingloader.close();

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: result.message,
                        });

                        this.activeStep = 3;

                    }).catch((error) => {

                        productProcessingloader.close();

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: "Something went wrong while processing the products based on the mapped headers.",
                        });

                    })

                }

            }else if(platform == 'woocommerce'){

                if(this.woocommerceFinalizedCsvProducts.length > 0){

                    this.woocommerceActiveStep = 3

                }else{

                    let productProcessingloader = Loading.service({
                        fullscreen: true,
                        text: 'Processing products based on the mapped headers. Please Wait!',
                    });

                    new Promise((resolve, reject) => {
                        
                        let finalizedProducts = [];

                        for(let i = 0 ; i < this.woocommerceCsvFileProducts.length ; i++){

                            let csvProduct = {};

                            csvProduct['id'] = uuidv4();

                            for(let j = 0 ; j < this.woocommerceHeaders.length ; j++){
                            
                                const mappedTo = this.woocommerceHeaders[j].mappedTo;

                                const value = this.woocommerceCsvFileProducts[i].product[mappedTo];

                                if(value){

                                    if(this.woocommerceHeaders[j].columnName == 'isActive' ||
                                    this.woocommerceHeaders[j].columnName == 'isFeatured'){

                                        csvProduct[this.woocommerceHeaders[j].columnName] = value == 1 ? 1 : 0

                                    }else{

                                        csvProduct[this.woocommerceHeaders[j].columnName] = value;

                                    }

                                }else{

                                    csvProduct[this.woocommerceHeaders[j].columnName] = null;

                                }
                            
                            }

                            csvProduct['images'] = this.woocommerceCsvFileProducts[i].images;

                            //if product have variants
                            if(this.woocommerceCsvFileProducts[i].variants.length > 0){
                                
                                let processedVariants = [];
                                
                                if(this.woocommerceCsvFileProducts[i].product['Attribute 1 name'] && 
                                this.woocommerceCsvFileProducts[i].product['Attribute 2 name'] && 
                                this.woocommerceCsvFileProducts[i].product['Attribute 3 name']){
                                
                                    //when 'Attribute 1 name', 'Attribute 2 name' & 'Attribute 3 name' are present
                                    if(this.woocommerceCsvFileProducts[i].product['Attribute 1 name'] != '' && 
                                    this.woocommerceCsvFileProducts[i].product['Attribute 2 name'] != '' &&
                                    this.woocommerceCsvFileProducts[i].product['Attribute 3 name'] != ''){

                                        //product info copying as variant
                                        // this.woocommerceCsvFileProducts[i].variants.unshift(this.woocommerceCsvFileProducts[i].product);

                                        //getting option1, option2 & option3 values
                                        let option1Values = [];
                                        let option2Values = [];
                                        let option3Values = [];

                                        for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){
                                        
                                            if((this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'] != '') && 
                                            (!option1Values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']))){

                                                option1Values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                            }

                                            if((this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'] != '') && 
                                            (!option2Values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']))){

                                                option2Values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                            }
                                            
                                            if((this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)'] != '') && 
                                            (!option3Values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)']))){

                                                option3Values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)']);

                                            }

                                        }

                                        //option creating
                                        let productOptions = [
                                            {
                                                name: this.woocommerceCsvFileProducts[i].product['Attribute 1 name'],
                                                values: [],
                                            },
                                            {
                                                name: this.woocommerceCsvFileProducts[i].product['Attribute 2 name'],
                                                values: [],
                                            },
                                            {
                                                name: this.woocommerceCsvFileProducts[i].product['Attribute 3 name'],
                                                values: [],
                                            },
                                        ]

                                        if(option1Values.length > 0 && option2Values.length == 0 && option3Values.length == 0){
                                        
                                            option1Values.forEach((optValue1) => {

                                                let tempVariant = {
                                                    name: optValue1,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    sale_price: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    isActive: 0,
                                                    value1: optValue1,
                                                }

                                                processedVariants.push(tempVariant);

                                            });

                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){
                                            
                                                processedVariants.forEach((pVariant) => {

                                                    if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']){

                                                        let variantWeight = null;

                                                        if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                            if(this.settings.weighing_unit != 'Kg'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                            }

                                                        }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                            if(this.settings.weighing_unit != 'lb'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                            }

                                                        }

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                        pVariant.isActive = 1;

                                                    }

                                                });

                                                //pushing option values
                                                if(!productOptions[0].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'])){

                                                    productOptions[0].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                                }

                                            }

                                        }else if(option1Values.length == 0 && option2Values.length > 0 && option3Values.length == 0){

                                            option2Values.forEach((optValue2) => {

                                                let tempVariant = {
                                                name: optValue2,
                                                sku: '',
                                                weight: '',
                                                price: '',
                                                sale_price: '',
                                                qty: 0,
                                                barcode: '',
                                                isActive: 0,
                                                value1: optValue2,
                                                }

                                                processedVariants.push(tempVariant);

                                            });

                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                                processedVariants.forEach((pVariant) => {

                                                    if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']){

                                                        let variantWeight = null;

                                                        if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                            if(this.settings.weighing_unit != 'Kg'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                            }

                                                        }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                            if(this.settings.weighing_unit != 'lb'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                            }

                                                        }

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'],

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null

                                                        pVariant.isActive = 1;

                                                    }

                                                });

                                                //pushing option values
                                                if(!productOptions[1].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                    productOptions[1].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                                }

                                            }

                                        }else if(option1Values.length == 0 && option2Values.length == 0 && option3Values.length > 0){

                                            option3Values.forEach((optValue3) => {

                                                let tempVariant = {
                                                    name: optValue3,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    sale_price: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    isActive: 0,
                                                    value1: optValue3,
                                                }

                                                processedVariants.push(tempVariant);

                                            });

                                        for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)']){

                                                    let variantWeight = null;

                                                    if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                        if(this.settings.weighing_unit != 'Kg'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                        }

                                                    }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                        if(this.settings.weighing_unit != 'lb'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                        }

                                                    }

                                                    pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                    this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                    this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                    pVariant.weight = variantWeight;

                                                    pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                    pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                    pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                    pVariant.isActive = 1;

                                                }

                                            });

                                            //pushing option values
                                            if(!productOptions[2].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)'])){

                                                productOptions[2].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)']);

                                            }

                                        }

                                        }else if(option1Values.length > 0 && option2Values.length > 0 && option3Values.length == 0){

                                            option1Values.forEach((optValue1) =>{

                                                option2Values.forEach((optValue2) =>{
                                                
                                                let tempVariant = {
                                                    name: optValue1 + '-' + optValue2,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    sale_price: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    isActive: 0,
                                                    value1: optValue1,
                                                    value2: optValue2,
                                                }

                                                processedVariants.push(tempVariant);

                                                });

                                            });

                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                                processedVariants.forEach((pVariant) => {

                                                    if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'] && 
                                                    pVariant.value2 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']){

                                                        let variantWeight = null;

                                                        if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                            if(this.settings.weighing_unit != 'Kg'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                            }

                                                        }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                            if(this.settings.weighing_unit != 'lb'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                            }

                                                        }

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                        pVariant.isActive = 1;

                                                    }

                                                });

                                                //pushing option values
                                                if(!productOptions[0].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'])){

                                                    productOptions[0].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                                }

                                                if(!productOptions[1].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                    productOptions[1].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                                }

                                            }

                                        }else if(option1Values.length > 0 && option2Values.length == 0 && option2Values.length > 0){

                                            option1Values.forEach((optValue1) =>{

                                                option3Values.forEach((optValue3) =>{
                                                
                                                let tempVariant = {
                                                    name: optValue1 + '-' + optValue3,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    sale_price: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    isActive: 0,
                                                    value1: optValue1,
                                                    value2: optValue3,
                                                }

                                                processedVariants.push(tempVariant);

                                                });

                                            });

                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                                processedVariants.forEach((pVariant) => {

                                                    if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'] && 
                                                    pVariant.value2 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)']){

                                                        let variantWeight = null;

                                                        if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                            if(this.settings.weighing_unit != 'Kg'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                            }

                                                        }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                            if(this.settings.weighing_unit != 'lb'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                            }

                                                        }

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                        pVariant.isActive = 1;

                                                    }

                                                });

                                                //pushing option values
                                                if(!productOptions[0].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'])){

                                                    productOptions[0].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                                }

                                                if(!productOptions[2].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)'])){

                                                    productOptions[2].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)']);

                                                }

                                            }

                                        }else if(option1Values.length == 0 && option2Values.length > 0 && option2Values.length > 0){

                                            option2Values.forEach((optValue2) =>{

                                                option3Values.forEach((optValue3) =>{
                                                
                                                    let tempVariant = {
                                                        name: optValue2 + '-' + optValue3,
                                                        sku: '',
                                                        weight: '',
                                                        price: '',
                                                        sale_price: '',
                                                        qty: 0,
                                                        barcode: '',
                                                        isActive: 0,
                                                        value1: optValue2,
                                                        value2: optValue3,
                                                    }

                                                    processedVariants.push(tempVariant);

                                                });

                                            });

                                        for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                            processedVariants.forEach((pVariant) => {

                                                if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'] && 
                                                pVariant.value2 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)']){

                                                    let variantWeight = null;

                                                    if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                        if(this.settings.weighing_unit != 'Kg'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                        }

                                                    }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                        if(this.settings.weighing_unit != 'lb'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                        }

                                                    }

                                                    pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                    this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                    this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                    pVariant.weight = variantWeight;

                                                    pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                    pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                    pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                    pVariant.isActive = 1;

                                                }

                                            })

                                            //pushing option values
                                            if(!productOptions[1].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                productOptions[1].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                            }

                                            if(!productOptions[2].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)'])){

                                                productOptions[2].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)']);

                                            }

                                        }

                                        }else if(option1Values.length > 0 && option2Values.length > 0 && option2Values.length > 0){

                                            option1Values.forEach((optValue1) =>{

                                                option2Values.forEach((optValue2) =>{

                                                    option3Values.forEach((optValue3) =>{

                                                        let tempVariant = {
                                                        name: optValue1 + '-' + optValue2 + '-' + optValue3,
                                                        sku: '',
                                                        weight: '',
                                                        price: '',
                                                        sale_price: '',
                                                        qty: 0,
                                                        barcode: '',
                                                        isActive: 0,
                                                        value1: optValue1,
                                                        value2: optValue2,
                                                        value3: optValue3,
                                                        }

                                                        processedVariants.push(tempVariant);

                                                    });

                                                });

                                            });

                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                                processedVariants.forEach((pVariant) => {

                                                if((pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']) && 
                                                (pVariant.value2 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']) && 
                                                (pVariant.value3 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)'])){

                                                    let variantWeight = null;

                                                    if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                        if(this.settings.weighing_unit != 'Kg'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                        }

                                                    }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                        if(this.settings.weighing_unit != 'lb'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                        }

                                                    }

                                                    pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                    this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                    this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                    pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                    pVariant.weight = variantWeight;

                                                    pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                    pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                    pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                    pVariant.isActive = 1;

                                                }

                                                });

                                                //pushing option values
                                                if(!productOptions[0].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'])){

                                                    productOptions[0].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                                }

                                                if(!productOptions[1].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                    productOptions[1].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                                }

                                                if(!productOptions[2].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)'])){

                                                    productOptions[2].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 3 value(s)']);

                                                }

                                            }

                                        }

                                        for(let l = 0 ; l < processedVariants.length ; l++){

                                            csvProduct['variant'] = _.cloneDeep(processedVariants[l]);

                                            csvProduct['options'] = _.cloneDeep(productOptions);

                                            csvProduct['product_type'] = 'variable';

                                            let tempCsvProduct = _.cloneDeep(csvProduct);

                                            finalizedProducts.push(tempCsvProduct);

                                        }

                                    }

                                    //when 'Attribute 1 name' & 'Attribute 2 name' are present
                                    if(this.woocommerceCsvFileProducts[i].product['Attribute 1 name'] != '' &&
                                    this.woocommerceCsvFileProducts[i].product['Attribute 2 name'] != '' &&
                                    this.woocommerceCsvFileProducts[i].product['Attribute 3 name'] == ''){

                                        //product info copying as variant
                                        // this.woocommerceCsvFileProducts[i].variants.unshift(this.woocommerceCsvFileProducts[i].product);

                                        //getting option1 & option2 values
                                        let option1Values = [];
                                        let option2Values = [];

                                        for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){
                                        
                                            if((this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'] != '') && 
                                            (!option1Values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']))){

                                                option1Values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                            }

                                            if((this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'] != '') && 
                                            (!option2Values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']))){

                                                option2Values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                            }

                                        }

                                        //option creating
                                        let productOptions = [
                                            {
                                                name: this.woocommerceCsvFileProducts[i].product['Attribute 1 name'],
                                                values: [],
                                            },
                                            {
                                                name: this.woocommerceCsvFileProducts[i].product['Attribute 2 name'],
                                                values: [],
                                            },
                                        ]

                                        if(option1Values.length > 0 && option2Values.length == 0){

                                            option1Values.forEach((optValue1) => {

                                                let tempVariant = {
                                                    name: optValue1,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    sale_price: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    isActive: 0,
                                                    value1: optValue1,
                                                }

                                                processedVariants.push(tempVariant);

                                            });

                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                                processedVariants.forEach((pVariant) => {

                                                    if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']){

                                                        let variantWeight = null;

                                                        if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                            if(this.settings.weighing_unit != 'Kg'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                            }

                                                        }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                            if(this.settings.weighing_unit != 'lb'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                            }

                                                        }

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                        pVariant.isActive = 1;

                                                    }

                                                });

                                                //pushing option values
                                                if(!productOptions[0].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'])){

                                                    productOptions[0].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                                }

                                            }

                                        }else if(option1Values.length == 0 && option2Values.length > 0){
                                        
                                            option2Values.forEach((optValue2) => {

                                                let tempVariant = {
                                                    name: optValue2,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    sale_price: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    isActive: 0,
                                                    value1: optValue2,
                                                }

                                                processedVariants.push(tempVariant);

                                            })

                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){
                                            
                                                processedVariants.forEach((pVariant) => {

                                                    if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']){

                                                        let variantWeight = null;

                                                        if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                            if(this.settings.weighing_unit != 'Kg'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                            }

                                                        }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                            if(this.settings.weighing_unit != 'lb'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                            }

                                                        }

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                        pVariant.isActive = 1;

                                                    }

                                                })

                                                //pushing option values
                                                if(!productOptions[1].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                    productOptions[1].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                                }

                                            }
                                            
                                        }else{

                                            option1Values.forEach((optValue1) => {

                                                option2Values.forEach((optValue2) =>{

                                                    let tempVariant = {
                                                        name: optValue1 + '-' + optValue2,
                                                        sku: '',
                                                        weight: '',
                                                        price: '',
                                                        sale_price: '',
                                                        qty: 0,
                                                        barcode: '',
                                                        isActive: 0,
                                                        value1: optValue1,
                                                        value2: optValue2,
                                                    }

                                                    processedVariants.push(tempVariant);

                                                });

                                            });


                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                                processedVariants.forEach((pVariant) => {

                                                    let variantWeight = null;

                                                    if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                        if(this.settings.weighing_unit != 'Kg'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                        }

                                                    }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                        if(this.settings.weighing_unit != 'lb'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                        }

                                                    }

                                                    if((pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']) && 
                                                    (pVariant.value2 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                        pVariant.isActive = 1;

                                                    }

                                                });

                                                //pushing option values
                                                if(!productOptions[0].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'])){

                                                    productOptions[0].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                                }

                                                if(!productOptions[1].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                    productOptions[1].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                                }

                                            }

                                        }

                                        for(let l = 0 ; l < processedVariants.length ; l++){

                                            csvProduct['variant'] = _.cloneDeep(processedVariants[l]);

                                            csvProduct['options'] = _.cloneDeep(productOptions);

                                            csvProduct['product_type'] = 'variable';

                                            let tempCsvProduct = _.cloneDeep(csvProduct);

                                            finalizedProducts.push(tempCsvProduct);

                                        }

                                    }

                                    //when only 'Attribute 1 name' is present
                                    if(this.woocommerceCsvFileProducts[i].product['Attribute 1 name'] != '' && 
                                    this.woocommerceCsvFileProducts[i].product['Attribute 2 name'] == '' &&
                                    this.woocommerceCsvFileProducts[i].product['Attribute 3 name'] == ''){

                                        // let productWeight = null;

                                        // if(this.woocommerceCsvFileProducts[i].product['Weight (kg)']){

                                        //   if(this.settings.weighing_unit != 'Kg'){

                                        //     productWeight = (this.woocommerceCsvFileProducts[i].product['Weight (kg)'] * 2.20).toFixed(2)

                                        //   }else{

                                        //     productWeight = this.woocommerceCsvFileProducts[i].product['Weight (kg)']

                                        //   }

                                        // }else if(this.woocommerceCsvFileProducts[i].product['Weight (lbs)']){

                                        //   if(this.settings.weighing_unit != 'lb'){

                                        //     productWeight = (this.woocommerceCsvFileProducts[i].product['Weight (lbs)'] * 0.45).toFixed(2)

                                        //   }else{

                                        //     productWeight = this.woocommerceCsvFileProducts[i].product['Weight (lbs)']

                                        //   }

                                        // }

                                        //variable product info as a variant
                                        // let tempProductAsVariant = {
                                        //   name: this.woocommerceCsvFileProducts[i].product['Attribute 1 value(s)'],
                                        //   sku: this.woocommerceCsvFileProducts[i].product['SKU'] ? 
                                        //   this.woocommerceCsvFileProducts[i].product['SKU'] : 
                                        //   this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${i}`,
                                        //   weight: productWeight,
                                        //   price: this.woocommerceCsvFileProducts[i].product['Regular price'],
                                        //   sale_price: this.woocommerceCsvFileProducts[i].product['Sale price'],
                                        //   qty: this.woocommerceCsvFileProducts[i].product['Stock'] ? this.woocommerceCsvFileProducts[i].product['Stock'] : 0,
                                        //   barcode: this.woocommerceCsvFileProducts[i].product['Barcode'] ? this.woocommerceCsvFileProducts[i].product['Barcode'] : null,
                                        //   value1: this.woocommerceCsvFileProducts[i].product['Attribute 1 value(s)'],
                                        // }

                                        // processedVariants.push(tempProductAsVariant);

                                        //variable product variants info
                                        for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){
                                        
                                            let variantWeight = null;

                                            if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                if(this.settings.weighing_unit != 'Kg'){

                                                    variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                }else{

                                                    variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                }

                                            }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                if(this.settings.weighing_unit != 'lb'){

                                                    variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                }else{

                                                    variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                }

                                            }

                                            let tempVariant = {
                                                name: this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'],
                                                sku: this.woocommerceCsvFileProducts[i].variants[k]['SKU'],
                                                weight: variantWeight,
                                                price: this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0,
                                                sale_price: this.woocommerceCsvFileProducts[i].variants[k]['Sale price'],
                                                qty: this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0,
                                                barcode: this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null,
                                                isActive: 1,
                                                value1: this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'],
                                            }

                                            processedVariants.push(tempVariant);
                                        
                                        }

                                        //option creating
                                        let productOptions = [
                                            {
                                                name: this.woocommerceCsvFileProducts[i].product['Attribute 1 name'],
                                                values: [],
                                            }
                                        ]

                                        //appending variants info in formData (array method)
                                        for(let l = 0 ; l < processedVariants.length ; l++){
                                        
                                            productOptions[0].values.push(processedVariants[l].name);

                                        }

                                        for(let l = 0 ; l < processedVariants.length ; l++){

                                            csvProduct['variant'] = _.cloneDeep(processedVariants[l]);

                                            csvProduct['options'] = _.cloneDeep(productOptions);

                                            csvProduct['product_type'] = 'variable';

                                            let tempCsvProduct = _.cloneDeep(csvProduct);

                                            finalizedProducts.push(tempCsvProduct);

                                        }

                                    }

                                }else if(this.woocommerceCsvFileProducts[i].product['Attribute 1 name'] && 
                                this.woocommerceCsvFileProducts[i].product['Attribute 2 name'] && 
                                !this.woocommerceCsvFileProducts[i].product['Attribute 3 name']){
                                
                                    //when 'Attribute 1 name' & 'Attribute 2 name' are present
                                    if(this.woocommerceCsvFileProducts[i].product['Attribute 1 name'] != '' &&
                                    this.woocommerceCsvFileProducts[i].product['Attribute 2 name'] != ''){
                                    
                                        //product info copying as variant
                                        // this.woocommerceCsvFileProducts[i].variants.unshift(this.woocommerceCsvFileProducts[i].product);

                                        //getting option1 & option2 values
                                        let option1Values = [];
                                        let option2Values = [];

                                        for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){
                                        
                                            if((this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'] != '') && 
                                            (!option1Values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']))){

                                                option1Values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                            }

                                            if((this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'] != '') && 
                                            (!option2Values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']))){

                                                option2Values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                            }

                                        }

                                        //option creating
                                        let productOptions = [
                                            {
                                                name: this.woocommerceCsvFileProducts[i].product['Attribute 1 name'],
                                                values: [],
                                            },
                                            {
                                                name: this.woocommerceCsvFileProducts[i].product['Attribute 2 name'],
                                                values: [],
                                            },
                                        ]

                                        if(option1Values.length > 0 && option2Values.length == 0){

                                            option1Values.forEach((optValue1) => {

                                                let tempVariant = {
                                                    name: optValue1,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    sale_price: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    isActive:0,
                                                    value1: optValue1,
                                                }

                                                processedVariants.push(tempVariant);

                                            });

                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                                processedVariants.forEach((pVariant) => {

                                                    if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']){

                                                        let variantWeight = null;

                                                        if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                            if(this.settings.weighing_unit != 'Kg'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                            }

                                                        }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                            if(this.settings.weighing_unit != 'lb'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                            }

                                                        }

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? 
                                                        this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                        pVariant.isActive = 1;

                                                    }

                                                });

                                                //pushing option values
                                                if(!productOptions[0].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'])){

                                                    productOptions[0].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                                }

                                            }

                                        }else if(option1Values.length == 0 && option2Values.length > 0){
                                        
                                            option2Values.forEach((optValue2) => {

                                                let tempVariant = {
                                                    name: optValue2,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    sale_price: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    isActive:0,
                                                    value1: optValue2,
                                                }

                                                processedVariants.push(tempVariant);

                                            })

                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){
                                            
                                                processedVariants.forEach((pVariant) => {

                                                    if(pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']){

                                                        let variantWeight = null;

                                                        if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                            if(this.settings.weighing_unit != 'Kg'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                            }

                                                        }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                            if(this.settings.weighing_unit != 'lb'){

                                                                variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                            }else{

                                                                variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                            }

                                                        }

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? 
                                                        this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                        pVariant.isActive = 1;

                                                    }

                                                });

                                                //pushing option values
                                                if(!productOptions[1].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                    productOptions[1].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                                }

                                            }
                                        
                                        }else{

                                            option1Values.forEach((optValue1) => {

                                                option2Values.forEach((optValue2) =>{

                                                let tempVariant = {
                                                    name: optValue1 + '-' + optValue2,
                                                    sku: '',
                                                    weight: '',
                                                    price: '',
                                                    sale_price: '',
                                                    qty: 0,
                                                    barcode: '',
                                                    isActive: 0,
                                                    value1: optValue1,
                                                    value2: optValue2,
                                                }

                                                processedVariants.push(tempVariant);

                                                });

                                            });


                                            for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){

                                                processedVariants.forEach((pVariant) => {

                                                    let variantWeight = null;

                                                    if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                        if(this.settings.weighing_unit != 'Kg'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                        }

                                                    }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                        if(this.settings.weighing_unit != 'lb'){

                                                            variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                        }else{

                                                            variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                        }

                                                    }

                                                    if((pVariant.value1 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']) && 
                                                    (pVariant.value2 == this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                        pVariant.sku = this.woocommerceCsvFileProducts[i].variants[k]['SKU'] ?
                                                        this.woocommerceCsvFileProducts[i].variants[k]['SKU'] :
                                                        this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${k}`;

                                                        pVariant.qty = this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0;

                                                        pVariant.weight = variantWeight;

                                                        pVariant.price = this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? 
                                                        this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0;

                                                        pVariant.sale_price = this.woocommerceCsvFileProducts[i].variants[k]['Sale price'];

                                                        pVariant.barcode = this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null;

                                                        pVariant.isActive = 1;

                                                    }

                                                });

                                                //pushing option values
                                                if(!productOptions[0].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'])){

                                                    productOptions[0].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)']);

                                                }

                                                if(!productOptions[1].values.includes(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)'])){

                                                    productOptions[1].values.push(this.woocommerceCsvFileProducts[i].variants[k]['Attribute 2 value(s)']);

                                                }

                                            }

                                        }

                                        for(let l = 0 ; l < processedVariants.length ; l++){

                                            csvProduct['variant'] = _.cloneDeep(processedVariants[l]);

                                            csvProduct['options'] = _.cloneDeep(productOptions);

                                            csvProduct['product_type'] = 'variable';

                                            let tempCsvProduct = _.cloneDeep(csvProduct);

                                            finalizedProducts.push(tempCsvProduct);

                                        }

                                    }

                                    //when only 'Attribute 1 name' is present
                                    if(this.woocommerceCsvFileProducts[i].product['Attribute 1 name'] != '' && 
                                    this.woocommerceCsvFileProducts[i].product['Attribute 2 name'] == ''){
                                    
                                        // let productWeight = null;

                                        // if(this.woocommerceCsvFileProducts[i].product['Weight (kg)']){

                                        //   if(this.settings.weighing_unit != 'Kg'){

                                        //     productWeight = (this.woocommerceCsvFileProducts[i].product['Weight (kg)'] * 2.20).toFixed(2)

                                        //   }else{

                                        //     productWeight = this.woocommerceCsvFileProducts[i].product['Weight (kg)']

                                        //   }

                                        // }else if(this.woocommerceCsvFileProducts[i].product['Weight (lbs)']){

                                        //   if(this.settings.weighing_unit != 'lb'){

                                        //     productWeight = (this.woocommerceCsvFileProducts[i].product['Weight (lbs)'] * 0.45).toFixed(2)

                                        //   }else{

                                        //     productWeight = this.woocommerceCsvFileProducts[i].product['Weight (lbs)']

                                        //   }

                                        // }

                                        //variable product info as a variant
                                        // let tempProductAsVariant = {
                                        //   name: this.woocommerceCsvFileProducts[i].product['Attribute 1 value(s)'],
                                        //   sku: this.woocommerceCsvFileProducts[i].product['SKU'] ? 
                                        //   this.woocommerceCsvFileProducts[i].product['SKU'] : 
                                        //   this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${i}`,
                                        //   weight: productWeight,
                                        //   price: this.woocommerceCsvFileProducts[i].product['Regular price'],
                                        //   sale_price: this.woocommerceCsvFileProducts[i].product['Sale price'],
                                        //   qty: this.woocommerceCsvFileProducts[i].product['Stock'] ? this.woocommerceCsvFileProducts[i].product['Stock'] : 0,
                                        //   barcode: this.woocommerceCsvFileProducts[i].product['Barcode'] ? this.woocommerceCsvFileProducts[i].product['Barcode'] : null,
                                        //   value1: this.woocommerceCsvFileProducts[i].product['Attribute 1 value(s)'],
                                        // }

                                        // processedVariants.push(tempProductAsVariant);

                                        //variable product variants info
                                        for(let k = 0 ; k < this.woocommerceCsvFileProducts[i].variants.length ; k++){
                                        
                                            let variantWeight = null;

                                            if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)']){

                                                if(this.settings.weighing_unit != 'Kg'){

                                                    variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'] * 2.20).toFixed(2);

                                                }else{

                                                    variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (kg)'];

                                                }

                                            }else if(this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)']){

                                                if(this.settings.weighing_unit != 'lb'){

                                                    variantWeight = (this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'] * 0.45).toFixed(2);

                                                }else{

                                                    variantWeight = this.woocommerceCsvFileProducts[i].variants[k]['Weight (lbs)'];

                                                }

                                            }

                                            let tempVariant = {
                                                name: this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'],
                                                sku: this.woocommerceCsvFileProducts[i].variants[k]['SKU'],
                                                weight: variantWeight,
                                                price: this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] ? this.woocommerceCsvFileProducts[i].variants[k]['Regular price'] : 0,
                                                sale_price: this.woocommerceCsvFileProducts[i].variants[k]['Sale price'],
                                                qty: this.woocommerceCsvFileProducts[i].variants[k]['Stock'] ? this.woocommerceCsvFileProducts[i].variants[k]['Stock'] : 0,
                                                barcode: this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] ? this.woocommerceCsvFileProducts[i].variants[k]['Barcode'] : null,
                                                isActive: 1,
                                                value1: this.woocommerceCsvFileProducts[i].variants[k]['Attribute 1 value(s)'],
                                            }

                                            processedVariants.push(tempVariant);
                                        
                                        }

                                        //option creating
                                        let productOptions = [
                                            {
                                                name: this.woocommerceCsvFileProducts[i].product['Attribute 1 name'],
                                                values: [],
                                            }
                                        ]

                                        //appending variants info in formData (array method)
                                        for(let l = 0 ; l < processedVariants.length ; l++){
                                            
                                            productOptions[0].values.push(processedVariants[l].name);

                                        } 

                                        for(let l = 0 ; l < processedVariants.length ; l++){

                                            csvProduct['variant'] = _.cloneDeep(processedVariants[l]);

                                            csvProduct['options'] = _.cloneDeep(productOptions);

                                            csvProduct['product_type'] = 'variable';

                                            let tempCsvProduct = _.cloneDeep(csvProduct);

                                            finalizedProducts.push(tempCsvProduct);

                                        }

                                    }

                                }

                            }else{

                                let productWeight = null;

                                if(this.woocommerceCsvFileProducts[i].product['Weight (kg)']){

                                    if(this.settings.weighing_unit != 'Kg'){

                                        productWeight = (this.woocommerceCsvFileProducts[i].product['Weight (kg)'] * 2.20).toFixed(2);

                                    }else{

                                        productWeight = this.woocommerceCsvFileProducts[i].product['Weight (kg)'];

                                    }

                                }else if(this.woocommerceCsvFileProducts[i].product['Weight (lbs)']){

                                    if(this.settings.weighing_unit != 'lb'){

                                        productWeight = (this.woocommerceCsvFileProducts[i].product['Weight (lbs)'] * 0.45).toFixed(2);

                                    }else{

                                        productWeight = this.woocommerceCsvFileProducts[i].product['Weight (lbs)'];

                                    }

                                }

                                //simple product info as variant
                                let tempProductAsVariant = {
                                    name: this.woocommerceCsvFileProducts[i].product['Name'],
                                    sku: this.woocommerceCsvFileProducts[i].product['SKU'] ? 
                                    this.woocommerceCsvFileProducts[i].product['SKU'] : 
                                    this.woocommerceCsvFileProducts[i].product['Name'].toLowerCase().replace(/\s+/g, '-') + `-${i}`,
                                    weight: productWeight,
                                    price: this.woocommerceCsvFileProducts[i].product['Regular price'] ? this.woocommerceCsvFileProducts[i].product['Regular price'] : 0,
                                    sale_price: this.woocommerceCsvFileProducts[i].product['Sale price'],
                                    qty: this.woocommerceCsvFileProducts[i].product['Stock'] ? this.woocommerceCsvFileProducts[i].product['Stock'] : 0,
                                    barcode: this.woocommerceCsvFileProducts[i].product['Barcode'] ? this.woocommerceCsvFileProducts[i].product['Barcode'] : null,
                                    isActive: 1,
                                    value1: this.woocommerceCsvFileProducts[i].product['Name'],
                                }

                                //option creating
                                let productOptions = [
                                    {
                                        name: this.woocommerceCsvFileProducts[i].product['Name'],
                                        values: [],
                                    }
                                ]

                                csvProduct['variant'] = tempProductAsVariant;

                                csvProduct['options'] = productOptions;

                                csvProduct['product_type'] = 'simple';

                                finalizedProducts.push(csvProduct);

                            }

                        }

                        const perPageItems = 20;

                        const paginatedFinalizedProducts = [];

                        for(let i = 0; i < finalizedProducts.length; i += perPageItems){

                            const chunk = finalizedProducts.slice(i, i + perPageItems);

                            paginatedFinalizedProducts.push({
                                page_no: Math.floor(i / perPageItems) + 1,
                                products: chunk
                            });

                        }

                        this.woocommerceFinalizedCsvProducts = _.cloneDeep(paginatedFinalizedProducts);

                        this.woocommerceCurrentPageProducts = _.cloneDeep(this.woocommerceFinalizedCsvProducts[0]);

                        this.woocommerceMainPagination = {
                            current_page: 1,
                            total: this.woocommerceFinalizedCsvProducts.length,
                            previous_page: 0,
                            last_page: paginatedFinalizedProducts[paginatedFinalizedProducts.length - 1].page_no,
                        }

                        resolve({
                            message:"The products have been successfully processed and are ready to be uploaded.",
                        });

                    }).then((result) => {

                        productProcessingloader.close();

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: result.message,
                        });

                        this.woocommerceActiveStep = 3;

                    }).catch((error) => {

                        productProcessingloader.close();

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: "Something went wrong while processing the products based on the mapped headers.",
                        });

                    });

                }

            }

        },
        mainPaginationCurrentPageChangeHandle(currentPage,pageSize){

            let currentIndex = this.finalizedCsvProducts.findIndex((csvProduct) => csvProduct.page_no == this.currentPageProducts.page_no);

            if(currentIndex != -1){

                this.finalizedCsvProducts[currentIndex] = _.cloneDeep(this.currentPageProducts);

            }

            let productsIndex = this.finalizedCsvProducts.findIndex((csvProduct) => csvProduct.page_no == currentPage);

            if(productsIndex != -1){

                this.currentPageProducts = _.cloneDeep(this.finalizedCsvProducts[productsIndex])

            }

        },
        woocommerceMainPaginationCurrentPageChangeHandle(currentPage,pageSize){

            let currentIndex = this.woocommerceFinalizedCsvProducts.findIndex((csvProduct) => csvProduct.page_no == this.woocommerceCurrentPageProducts.page_no);

            if(currentIndex != -1){

                this.woocommerceFinalizedCsvProducts[currentIndex] = _.cloneDeep(this.woocommerceCurrentPageProducts);

            }

            let productsIndex = this.woocommerceFinalizedCsvProducts.findIndex((csvProduct) => csvProduct.page_no == currentPage);

            if(productsIndex != -1){

                this.woocommerceCurrentPageProducts = _.cloneDeep(this.woocommerceFinalizedCsvProducts[productsIndex])

            }

        },
        csvAddDefaultQtyHandle(platform){

            if(platform == 'shopify'){

                if(this.csvDefaultQty.length == 0){

                    this.$notify({
                        type: "info",
                        title: "Alert",
                        message: "Stock field is required.",
                    });

                    return 

                }

                if(this.csvDefaultQty > 10000000){

                    this.$notify({
                        type: "info",
                        title: "Alert",
                        message: "Stock value may not be greater than 10000000.",
                    });

                    return

                }

                let regx = new RegExp(/^(?!0\d)\d*$/);

                if(!regx.test(this.csvDefaultQty)){

                    this.$notify({
                        type: "info",
                        title: "Alert",
                        message: "Stock value is invalid.",
                    });

                    return

                }

                MessageBox.confirm(
                    "Do you really want to add a default stock value in all the listed products?.", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(() => {

                    let productProcessingloader = Loading.service({
                        fullscreen: true,
                        text: 'Processing products based on the mapped headers. Please Wait!',
                    });

                    new Promise((resolve, reject) => {

                        this.currentPageProducts.products.forEach((csvProduct) => {

                            csvProduct.variant.qty = this.csvDefaultQty;

                        });

                        this.finalizedCsvProducts.forEach((csvProduct) => {

                            csvProduct.products.forEach((paginatedCsvProduct) => {

                                paginatedCsvProduct.variant.qty = this.csvDefaultQty;

                            });

                        });

                        this.csvDefaultQty = 0;

                        resolve({
                            message:"Default stock value has been successfully added."
                        });

                    }).then((result) => {

                        productProcessingloader.close();

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: result.message,
                        });

                    }).catch((error) => {

                        productProcessingloader.close();
                        
                        this.$message({
                            type: "error",
                            showClose: true,
                            message: "Something went wrong while adding default stock value to the listed products.",
                        });

                    })

                }).catch(() => {})

            }else if(platform == 'woocommerce'){

                if(this.woocommerceCsvDefaultQty.length == 0){

                    this.$notify({
                        type: "info",
                        title: "Alert",
                        message: "Stock field is required.",
                    });

                    return 

                }

                if(this.woocommerceCsvDefaultQty > 10000000){

                    this.$notify({
                        type: "info",
                        title: "Alert",
                        message: "Stock value may not be greater than 10000000.",
                    });

                    return

                }

                let regx = new RegExp(/^(?!0\d)\d*$/);

                if(!regx.test(this.woocommerceCsvDefaultQty)){

                    this.$notify({
                        type: "info",
                        title: "Alert",
                        message: "Stock value is invalid.",
                    });

                    return

                }

                MessageBox.confirm(
                    "Do you really want to add a default stock value in all the listed products?.", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(() => {

                    let productProcessingloader = Loading.service({
                        fullscreen: true,
                        text: 'Processing products based on the mapped headers. Please Wait!',
                    });

                    new Promise((resolve, reject) => {

                        this.woocommerceCurrentPageProducts.products.forEach((csvProduct) => {

                            csvProduct.variant.qty = this.woocommerceCsvDefaultQty;

                        });

                        this.woocommerceFinalizedCsvProducts.forEach((csvProduct) => {

                            csvProduct.products.forEach((paginatedCsvProduct) => {

                                paginatedCsvProduct.variant.qty = this.woocommerceCsvDefaultQty;

                            });

                        });

                        this.woocommerceCsvDefaultQty = 0;

                        resolve({
                            message:"Default stock value has been successfully added."
                        });

                    }).then((result) => {

                        productProcessingloader.close();

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: result.message,
                        });

                    }).catch(() => {

                        productProcessingloader.close();
                        
                        this.$message({
                            type: "error",
                            showClose: true,
                            message: "Something went wrong while adding default stock value to the listed products.",
                        });

                    });

                }).catch(() => {})

            }

        },
        async csvDataUploadHandle(platform){

            MessageBox.confirm(
                "Do you really want to proceed? Reviewing all the considerations can assist you in preventing any potential issues.", 
                "Warning", 
                {
                    type: "warning",
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                }
            ).then(async () => {

                if(platform == 'shopify'){

                    let currentIndex = this.finalizedCsvProducts.findIndex((csvProduct) => csvProduct.page_no == this.currentPageProducts.page_no);

                    if(currentIndex != -1){

                        this.finalizedCsvProducts[currentIndex] = _.cloneDeep(this.currentPageProducts);

                    }

                    const productMap = new Map();

                    this.finalizedCsvProducts.forEach(page => {

                        page.products.forEach(product => {

                            if(!productMap.has(product.id)){

                                //Initialize with product data and an empty variants array
                                const { variant, ...productWithoutVariant } = product;
                                productMap.set(product.id, { ...productWithoutVariant, variants: [] });

                            }

                            //Push the variant into the corresponding product's variants array
                            productMap.get(product.id).variants.push(product.variant);

                        });

                    });

                    //Convert the productMap to an array of products
                    const mergedProducts = Array.from(productMap.values());

                    this.closeCsvPopupHandle('shopify');

                    let formData = new FormData();

                    formData.append('platform',"shopify");
                    formData.append('products',JSON.stringify(mergedProducts));

                    let loader = Loading.service({
                        fullscreen: true,
                        text: 'Submitting import request. Please Wait!',
                    });

                    try{
                        let res = await this.$axios.post('product/csv-import/request/initiate',formData);
                        if(res.data.status_code == '1365'){

                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: res.data.message,
                            });

                        }
                    }catch(error){

                        if(error.response){

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: "Something went wrong with the csv import request submission. Kindly contact the support team for further assistance.",
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }
                    
                    }finally{

                        loader.close();

                    }

                }else if(platform == 'woocommerce'){

                    let currentIndex = this.woocommerceFinalizedCsvProducts.findIndex((csvProduct) => csvProduct.page_no == this.woocommerceCurrentPageProducts.page_no);

                    if(currentIndex != -1){

                        this.woocommerceFinalizedCsvProducts[currentIndex] = _.cloneDeep(this.woocommerceCurrentPageProducts);

                    }

                    const productMap = new Map();

                    this.woocommerceFinalizedCsvProducts.forEach(page => {

                        page.products.forEach(product => {

                            if(!productMap.has(product.id)){

                                //Initialize with product data and an empty variants array
                                const { variant, ...productWithoutVariant } = product;
                                productMap.set(product.id, { ...productWithoutVariant, variants: [] });

                            }

                            //Push the variant into the corresponding product's variants array
                            productMap.get(product.id).variants.push(product.variant);

                        });

                    });

                    //Convert the productMap to an array of products
                    const mergedProducts = Array.from(productMap.values());

                    this.closeCsvPopupHandle('woocommerce');

                    let formData = new FormData();

                    formData.append('platform','woocommerce');
                    formData.append('products',JSON.stringify(mergedProducts));

                    let loader = Loading.service({
                        fullscreen: true,
                        text: 'Submitting import request. Please Wait!',
                    });

                    try{
                        let res = await this.$axios.post('product/csv-import/request/initiate',formData);
                        if(res.data.status_code == '1365'){
                        
                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: res.data.message,
                            });
                        
                        }
                    }catch(error){

                        if(error.response){

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: "Something went wrong with the csv import request submission. Kindly contact the support team for further assistance.",
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }
                    
                    }finally{

                        loader.close();

                    }

                }

            }).catch(()=>{})

        },
        valueMappedCheck(platform,value){

            if(platform == 'shopify'){

                if(this.myHeaders.some((header)=> header.mappedTo == value)){

                    return true;

                }else{

                    return false;

                }

            }else if(platform == 'woocommerce'){

                if(this.woocommerceHeaders.some((header)=> header.mappedTo == value)){

                    return true;

                }else{

                    return false;

                }

            }

        },
    },
    mounted(){

    },
}
</script>

<style scoped>
    .productVariantsWrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:100%;
    }
    .productVariantsWrap .productVariantContainer{
        display:flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .productDataWrap{
        max-height: 500px;
        overflow-y: auto;
    }
    .masterInputWrap{
        margin-bottom: 30px;
    }
    .masterInputWrap input{
        border: 1px solid #E8E8E8 !important;
        width: 70%;
        border-radius: 4px;
        background: #FCFCFC;
        font-size: 14px !important;
        color: #9E9B9B;
        font-weight: 400 !important;
        padding: 9px 15px !important;
        height: auto;
    }
    .masterInputWrap button{
        background-color: #15223D;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        border: none;
        padding: 10px 10px;
        border-radius: 4px;
        /* min-width: 119px; */
        width: 30%;
        transition: all 0.3s ease-in-out;
    }
    /* .suggestionCol{
        display: flex;
        align-items: center;
    }
    .suggestionCol p{
        margin-bottom: 0px !important
    } */
    .el-step__title{
        line-height: 20px !important;
    }

    /* Transition Effects */
    .modal-enter-active, 
    .modal-leave-active {
        transition: all 0.3s ease-in-out;
    }

    /* Initial state when entering */
    .modal-enter{
        opacity: 0;
        visibility: hidden;
    }

    /* Final state when leaving */
    .modal-leave-to {
        opacity: 0;
        visibility: hidden;
    }
</style>